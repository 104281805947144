/*eslint-disable*/
import axios from 'axios';
import {baseApiUrl} from "../../../config.js";
import { loadModules } from 'esri-loader';

const state = {
  esriLoginStatus: false,
  esriPortalObject: {},
  mapWebScene: null,
  mapView: null,
  initialDiscussions: [],
  discussionComments: [],
  currentDiscussionId: null,
  currentDiscussion: null,
  currentComments: [],
  // userName: null,
  userEmail: null,
  user: null,
  commentAttachments: [],
  staticDiscussionLayerId: "discussion-graphics",
  staticDiscussionLayerTitle: "User Discussions",
  mapWebSceneLoadFlag: null,
  isAddNewDiscussion: false,
  userList: [],
  disciplineList: [],
  commentDiscipline: [],
  commentAdressedTo: [],
  primaryAssetTypeList: [],
  assetSubTypeList: [],
  climateHazardList: [],
  riskTypesList: [],
  consequenceCategoriesList: [],
  riskOwnersList: [],
  materialityList: [],
  assessmentConsequencesList: [],
  assessmentLikelihoodList: [],
  assessmentRatingList: [],
  craEntryList: [],
  currentProjectCRAEntryList: [],
  currentCRAEntryId: null,
  currentCRAEntry: null,
  viewCRAListFlag: null,
  viewCRAEntryFlag: null,
  selectedCRAStage: {
    code: "climateAssetContext",
    label: "Climate and Asset Context"
  },
  availableCRAStages: [
    {
        code: "climateAssetContext",
        label: "1. Climate and Asset Context"
    },
    {
        code: "preScreening",
        label: "2. Pre-Screening"
    },
    {
        code: "detailedRiskAssessment",
        label: "3. Detailed Risk Assessment"
    },
    {
        code: "adaptationPlanning",
        label: "4. Adaptation Planning",
        disabled: true
    },
  ],
  adaptationTypeList: []
}

function initialState() {
  return {
    esriLoginStatus: false,
    esriPortalObject: {},
    mapWebScene: null,
    mapView: null,
    initialDiscussions: [],
    discussionComments: [],
    currentDiscussionId: null,
    currentDiscussion: null,
    currentComments: [],
    // userName: null,
    userEmail: null,
    user: null,
    commentAttachments: [],
    staticDiscussionLayerId: "discussion-graphics",
    staticDiscussionLayerTitle: "User Discussions",
    mapWebSceneLoadFlag: null,
    isAddNewDiscussion: false,
    userList: [],
    disciplineList: [],
    commentDiscipline: [],
    commentAdressedTo: [],
    primaryAssetTypeList: [],
    assetSubTypeList: [],
    climateHazardList: [],
    riskTypesList: [],
    consequenceCategoriesList: [],
    riskOwnersList: [],
    materialityList: [],
    assessmentConsequencesList: [],
    assessmentLikelihoodList: [],
    assessmentRatingList: [],
    craEntryList: [],
    currentProjectCRAEntryList: [],
    currentCRAEntryId: null,
    currentCRAEntry: null,
    viewCRAListFlag: null,
    viewCRAEntryFlag: null,
    selectedCRAStage: {
        code: "climateAssetContext",
        label: "Climate and Asset Context"
    },
    availableCRAStages: [
        {
            code: "climateAssetContext",
            label: "Climate and Asset Context"
        },
        {
            code: "preScreening",
            label: "Pre-Screening"
        },
        {
            code: "detailedRiskAssessment",
            label: "Detailed Risk Assessment"
        },
        {
            code: "adaptationPlanning",
            label: "Adaptation Planning"
        },
    ],
    adaptationTypeList: []
  }
}
const mutations = {
  setESRILoginStatus(state, data) {
    state.esriLoginStatus = data
  },
  setESRIPortalObject(state, data) {
    state.esriPortalObject = data
  },
  saveMapWebScene(state, data) {
    state.mapWebScene = data
  },
  saveMapView(state, data) {
    state.mapView = data
  },
  // saveUserName(state, data) {
  //   state.userName = data
  // },
  saveUserEmail(state, data) {
    state.userEmail = data
  },
  saveUser(state, data) {
    state.user = data
  },
  insertUserList(state, payload){
    // state.userList = data
    state.userList.push(payload)
  },
  insertInitialDiscussions(state, payload) {
    state.initialDiscussions.push(payload)
  },
  resetInitialDiscussions(state) {
    state.initialDiscussions = []
  },
  resetDiscussionComments(state) {
    state.discussionComments = []
  },
  insertDiscussionComments(state, payload) {
    state.discussionComments.push(payload)
  },
  setCurrentDiscussionId(state, payload) {
    state.currentDiscussionId = payload
  },
  setCurrentDiscussion(state, payload) {
    state.currentDiscussion = payload
  },
  setCurrentComments(state, payload) {
    payload.comments.forEach(item=>{
      state.currentComments.push(item)
    })
  },
  clearCurrentComments(state) {
    state.currentComments = []
  },
  insertDisciplines(state, payload) {
    state.disciplineList.push(payload)
  },
  addNewComment(state, payload) {
    var objectId = payload.objectId
    var currentComments = state.discussionComments.filter(obj=>obj.objectId == objectId)
    if (currentComments.length) {
      currentComments[0].comments.push(payload.comment)
    }
    else {
      var discussionComment = {
        decision: payload.decision,
        approvalStateCode: payload.approvalStateCode,
        objectId: objectId,
        comments: [payload.comment]
      }
      state.discussionComments.push(discussionComment)
    }
  },
  finalizeDiscussion(state, payload) {
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload.objectId)
    currentDiscussion[0].approvalStatus = payload.decision?"Approved":currentDiscussion.approvalStatus
  },
  finalizeDetailsDiscussion(state, payload) {
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload.objectId)
    currentDiscussion[0].addressedTo = payload.addressedTo?payload.addressedTo:currentDiscussion[0].addressedTo
    currentDiscussion[0].disciplineList = payload.disciplineList.length>0?payload.disciplineList:currentDiscussion[0].disciplineList
  },
  clearAttachments(state) {
    state.commentAttachments = []
  },
  addAttachment(state, payload) {
    state.commentAttachments.push(payload)
  },
  removeAttachment(state, index) {
    state.commentAttachments.splice(index,1)
  },
  setMapWebSceneLoadFlag(state, payload) {
    state.mapWebSceneLoadFlag = payload
  },
  setIfAddNewDiscussionClicked(state, payload){
    state.isAddNewDiscussion = payload
  },
  clearcCommentDiscipline(state) {
    state.commentDiscipline = []
  },
  clearCommentAdressedTo(state) {
    state.commentAdressedTo = []
  },
  addCommentDiscipline(state, payload) {
    state.commentDiscipline = payload
  },
  addCommentAdressedTo(state, payload) {
    state.commentAdressedTo = payload
  },
  clearPrimaryAssetTypes(state) {
    state.primaryAssetTypeList = []
  },
  setPrimaryAssetTypes(state, payload) {
    let primaryAssetType = {}
    primaryAssetType.label = payload.name
    primaryAssetType.id = payload.id
    primaryAssetType.code = payload.code
    primaryAssetType.designLife = payload.design_life
    primaryAssetType.isActive = payload.is_active==1?true:false
    state.primaryAssetTypeList.push(primaryAssetType)
  },
  clearAssetSubTypes(state) {
    state.assetSubTypeList = []
  },
  setAssetSubTypes(state, payload) {
    let assetSubType = {}
    assetSubType.label = payload.name
    assetSubType.id = payload.id
    assetSubType.code = payload.code
    state.assetSubTypeList.push(assetSubType)
  },
  clearClimateHazards(state) {
    state.climateHazardList = []
  },
  setClimateHazards(state, payload) {
    let climateHazard = {}
    climateHazard.label = payload.name
    climateHazard.id = payload.id
    climateHazard.code = payload.code
    climateHazard.isActive = payload.is_active==1?true:false
    state.climateHazardList.push(climateHazard)
  },
  clearRiskTypes(state) {
    state.riskTypesList = []
  },
  setRiskTypes(state, payload) {
    let riskType = {}
    riskType.label = payload.name
    riskType.id = payload.id
    riskType.code = payload.code
    state.riskTypesList.push(riskType)
  },
  clearConsequenceCategories(state) {
    state.consequenceCategoriesList = []
  },
  setConsequenceCategories(state, payload) {
    let consequenceCategory = {}
    consequenceCategory.label = payload.name
    consequenceCategory.id = payload.id
    consequenceCategory.code = payload.code
    state.consequenceCategoriesList.push(consequenceCategory)
  },
  clearRiskOwners(state) {
    state.riskOwnersList = []
  },
  setRiskOwners(state, payload) {
    let riskOwner = {}
    riskOwner.label = payload.name
    riskOwner.id = payload.id
    riskOwner.code = payload.code
    state.riskOwnersList.push(riskOwner)
  },
  clearMateriality(state) {
    state.materialityList = []
  },
  setMateriality(state, payload) {
    let materiality = {}
    materiality.label = payload.name
    materiality.id = payload.id
    materiality.code = payload.code
    state.materialityList.push(materiality)
  },
  clearAssessmentConsequence(state) {
    state.assessmentConsequencesList = []
  },
  setAssessmentConsequence(state, payload) {
    let assessmentConsequence = {}
    assessmentConsequence.label = payload.name
    assessmentConsequence.id = payload.id
    assessmentConsequence.code = payload.code
    state.assessmentConsequencesList.push(assessmentConsequence)
  },
  clearAssessmentLikelihood(state) {
    state.assessmentLikelihoodList = []
  },
  setAssessmentLikelihood(state, payload) {
    let assessmentLikelihood = {}
    assessmentLikelihood.label = payload.name
    assessmentLikelihood.id = payload.id
    assessmentLikelihood.code = payload.code
    state.assessmentLikelihoodList.push(assessmentLikelihood)
  },
  clearAssessmentRatings(state) {
    state.assessmentRatingList = []
  },
  setAssessmentRatings(state, payload) {
    let assessmentRating = {}
    assessmentRating.label = payload.name
    assessmentRating.id = payload.id
    assessmentRating.code = payload.code
    state.assessmentRatingList.push(assessmentRating)
  },
  clearCRAEntries(state) {
    state.craEntryList = []
  },
  setCRAEntries(state, payload) {
    state.craEntryList.push(payload)
  },
  setCurrentProjectCRAEntries(state, payload) {
    var currentProjectCRAEntry = state.craEntryList.filter(obj=>{return obj.project.id == payload})[0]
    state.currentProjectCRAEntryList = currentProjectCRAEntry.entries
  },
  clearCurrentProjectCRAEntries(state, payload) {
    state.currentProjectCRAEntryList = []
  },
  setCurrentCRAEntry(state, payload) {
    state.currentCRAEntryId = payload
    var currentCRAEntry = state.craEntryList.filter(obj=>{return obj.id == payload})
    state.currentCRAEntry = currentCRAEntry[0]
  },
  clearCurrentCRAEntry(state) {
    state.currentCRAEntryId = null
    state.currentCRAEntry = null
  },
  toggleIsViewDiscussions(state) {
    state.viewCRAListFlag = !state.viewCRAListFlag
  },
  setCRAStage(state, payload) {
    state.selectedCRAStage.code = state.availableCRAStages.filter(obj=>{return obj.code == payload})[0].code
    state.selectedCRAStage.label = state.availableCRAStages.filter(obj=>{return obj.code == payload})[0].label
  },
  clearAdaptationTypes(state, payload) {
    state.adaptationTypeList = []
  },
  setAdaptationTypes(state, payload) {
    var adaptationType = {}
    adaptationType.label = payload.name
    adaptationType.id = payload.id
    adaptationType.code = payload.code
    state.adaptationTypeList.push(adaptationType)
  }
}

const actions = {
  async fetchDiscussionComments ({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchComments"
      let data    = {
        discussionIds: payload.objectIds
      }
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        commit("resetDiscussionComments")
        result.data.forEach((item,index) => {
          commit('insertDiscussionComments', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
  setCurrentDiscussionDetails({state, commit}, payload) {
    commit("setCurrentDiscussionId", payload)
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload)
    if (currentDiscussion.length) {
      commit("setCurrentDiscussion", currentDiscussion)
    }
    var currentComments = state.discussionComments.filter(obj=>obj.objectId == payload)
    commit("clearCurrentComments")
    if (currentComments.length) {
      commit("setCurrentComments", currentComments[0])
    }
  },
  fetchDiscussionLayer({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject) {
      var url = baseApiUrl + "fetchDiscussions"
      var data = {}
      var config = {
        responseType: 'json'
      }
      axios.post(url, data, config).then(response=>{
        var discussionsList = response.data.features
        var discussionsCount = 0;
        loadModules([
          "esri/layers/GraphicsLayer",
          "esri/Graphic",
          "esri/PopupTemplate"
        ],{ css: true })
        .then(([
          GraphicsLayer,
          Graphic,
          PopupTemplate
        ]) => {
          const discussionGraphicsLayer = new GraphicsLayer({
            id: state.staticDiscussionLayerId,
            title: state.staticDiscussionLayerTitle
          });
          const popupTemplate = new PopupTemplate({
            title: "Discussion Details",
            content: [{
              type: "fields",
              fieldInfos: [
                {
                  fieldName: "id",
                  label: "Object ID"
                },
                {
                  fieldName: "discussionCreatedBy",
                  label: "Created by"
                },
                {
                  fieldName: "message",
                  label: "Message"
                },
                {
                  fieldName: "disciplineList",
                  label: "Discipline"
                },
                {
                  fieldName: "workPackage",
                  label: "Work Package"
                },
                {
                  fieldName: "date",
                  label: "Date"
                },
                {
                  fieldName: "approvalState",
                  label: "Approval Status"
                },
              ]
            }]
          });
          if (discussionsList) {
            discussionsList.forEach(discussion=>{
              var discussionEntry = new Graphic({
                geometry: {
                  type: "point",
                  latitude: discussion.geometry.latitude,
                  longitude:discussion.geometry.longitude,
                  z: discussion.geometry.height
                },
                attributes: discussion.attributes,
                symbol: {
                  type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                  url: discussion.attributes.approvalState == "Approved"? "ApprovedMarker.png":"DefaultMarker.png",
                  width: 20
                },
                popupTemplate: popupTemplate
              });
  
              discussionGraphicsLayer.graphics.push(discussionEntry)
  
            })
          }
          resolve(discussionGraphicsLayer)
        })
      })
    });
  },
  fetchDisciplines({commit, dispatch}) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchDisciplines"
      let data    = {}
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        result.data.forEach((item,index) => {
          commit('insertDisciplines', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
  fetchUsers({commit, dispatch}) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchUsers"
      let data    = {}
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        result.data.forEach((item,index) => {
          commit('insertUserList', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
  fetchCRAEntries({commit, dispatch}) {
    return new Promise(function(resolve, reject){
        try {
            let url = baseApiUrl + "climateRiskEntries"
            axios.get(url).then(result=>{
                commit("clearCRAEntries")
                result.data.forEach(item=>{
                    commit("setCRAEntries", item)
                })
                resolve(true)
            })
        } catch (error) {
            reject(error)
        }
    })
  },
  fetchAdaptationTypes({commit, dispatch}) {
    return new Promise(function(resolve, reject){
        try {
            let url = baseApiUrl + "adaptationTypes"
            axios.get(url).then(result=>{
                commit("clearAdaptationTypes")
                result.data.forEach(item=>{
                    commit("setAdaptationTypes", item)
                })
                resolve(true)
            })
        } catch (error) {
            reject(error)
        }
    })
  },
  updatePreScreenCRAEntry({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "updatePreScreenCRAEntry"
        let data = {
          id: payload.id,
          primaryAssetTypeCode: payload.primaryAssetTypeCode,
          assetSubTypeCode: payload.assetSubTypeCode,
          climateHazardCode: payload.climateHazardCode,
          riskTypeCode: payload.riskTypeCode,
          consequenceCategoryCode: payload.consequenceCategoryCode,
          riskOwnerCode: payload.riskOwnerCode,
          materialityCode: payload.materialityCode,
          design_life: payload.design_life,
          risk_description: payload.risk_description,
          risk_owner_text: payload.risk_owner_text,
          current_design_control_measure: payload.current_design_control_measure,
          specific_location: payload.specific_location,
          assigned_owner: payload.assigned_owner,
          stakeholders: payload.stakeholders,
          adaptation_engineering_design: payload.adaptation_engineering_design,
          adaptation_soft_management: payload.adaptation_soft_management,
          is_action_implemented: payload.is_action_implemented
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
          // commit('updateCurrentProjectCRAEntries', payload)
            // dispatch('fetchCRAEntries').then(()=>{
            //   console.log('fetchCRAEntries')
                resolve();
            // })
        })
    })
  },
  deleteCRAEntry({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "deleteCRAEntry"
        let data = {
          id: payload.id
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
           resolve();
        })
    })
  },
  updateRiskAssessmentCRAEntry({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "updateRiskAssessmentCRAEntry"
        let data = {
          id: payload.id,
          nfLikelihoodCode: payload.riskAssessmentLikelihoodNFCode,
          nfConsequenceCode: payload.riskAssessmentConsequenceNFCode,
          nfRiskRatingCode: payload.riskRatingNFCode,
          mfLikelihoodCode: payload.riskAssessmentLikelihoodMFCode,
          mfConsequenceCode: payload.riskAssessmentConsequenceMFCode,
          mfRiskRatingCode: payload.riskRatingMFCode,
          ffLikelihoodCode: payload.riskAssessmentLikelihoodFFCode,
          ffConsequenceCode: payload.riskAssessmentConsequenceFFCode,
          ffRiskRatingCode: payload.riskRatingFFCode,
          residualNFLikelihoodCode: payload.residualRiskAssessmentLikelihoodNFCode,
          residualNFConsequenceCode: payload.residualRiskAssessmentConsequenceNFCode,
          residualNFRiskRatingCode: payload.residualRiskRatingNFCode,
          residualMFLikelihoodCode: payload.residualRiskAssessmentLikelihoodMFCode,
          residualMFConsequenceCode: payload.residualRiskAssessmentConsequenceMFCode,
          residualMFRiskRatingCode: payload.residualRiskRatingMFCode,
          residualFFLikelihoodCode: payload.residualRiskAssessmentLikelihoodFFCode,
          residualFFConsequenceCode: payload.residualRiskAssessmentConsequenceFFCode,
          residualFFRiskRatingCode: payload.residualRiskRatingFFCode
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
            // commit('updateCurrentProjectCRAEntries', payload)
            // dispatch('fetchCRAEntries').then(()=>{
            //   console.log('fetchCRAEntries')
                resolve();
            // })
        })
    })
  },
  updateActivePrimaryAssetTypes({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "updateActivePrimaryAssetTypes"
        let data = {
            activeCodes: payload
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
            dispatch('fetchPrimaryAssetTypes').then(()=>{
                dispatch('fetchCRAEntries').then(()=>{
                    resolve();
                })
            })
        })
    })
  },
  fetchPrimaryAssetTypes({state, commit}) {
      return new Promise(function(resolve, reject){
          let url = baseApiUrl + "primaryAssetTypes"
          axios.get(url).then(result=>{
              commit('clearPrimaryAssetTypes')
              result.data.forEach(item=>{
                  commit('setPrimaryAssetTypes', item)
                })
                resolve()
            })
        })
    },
  fetchAssetSubTypes({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "assetSubTypes"
        axios.get(url).then(result=>{
            commit('clearAssetSubTypes')
            result.data.forEach(item=>{
                commit('setAssetSubTypes', item)
            })
            resolve()
        })
    })
  },
  updateActiveClimateHazards({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "updateActiveClimateHazards"
        let data = {
            activeCodes: payload.activeCodes,
            projectId: payload.projectId
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
            dispatch('fetchClimateHazards').then(()=>{
                dispatch('fetchCRAEntries').then(()=>{
                    resolve();
                })
            })
        })
    })
  },
  fetchClimateHazards({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "climateHazards"
        axios.get(url).then(result=>{
            commit('clearClimateHazards')
            result.data.forEach(item=>{
                commit('setClimateHazards', item)
            })
            resolve()
        })
    })
  },
  fetchRiskTypes({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "riskTypes"
        axios.get(url).then(result=>{
            commit('clearRiskTypes')
            result.data.forEach(item=>{
                commit('setRiskTypes', item)
            })
            resolve()
        })
    })
  },
  fetchConsequenceCategories({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "consequenceCategories"
        axios.get(url).then(result=>{
            commit('clearConsequenceCategories')
            result.data.forEach(item=>{
                commit('setConsequenceCategories', item)
            })
            resolve()
        })
    })
  },
  fetchRiskOwners({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "riskOwners"
        axios.get(url).then(result=>{
            commit('clearRiskOwners')
            result.data.forEach(item=>{
                commit('setRiskOwners', item)
            })
            resolve()
        })
    })
  },
  fetchMateriality({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "materiality"
        axios.get(url).then(result=>{
            commit('clearMateriality')
            result.data.forEach(item=>{
                commit('setMateriality', item)
            })
            resolve()
        })
    })
  },
  fetchAssessmentConsequence({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "assessmentConsequences"
        axios.get(url).then(result=>{
            commit('clearAssessmentConsequence')
            result.data.forEach(item=>{
                commit('setAssessmentConsequence', item)
            })
            resolve()
        })
    })
  },
  fetchAssessmentLikelihood({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "assessmentLikelihood"
        axios.get(url).then(result=>{
            commit('clearAssessmentLikelihood')
            result.data.forEach(item=>{
                commit('setAssessmentLikelihood', item)
            })
            resolve()
        })
    })
  },
  fetchAssessmentRatings({state, commit}) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "assessmentRatings"
        axios.get(url).then(result=>{
            commit('clearAssessmentRatings')
            result.data.forEach(item=>{
                commit('setAssessmentRatings', item)
            })
            resolve()
        })
    })
  },
  setCurrentCRAEntryProcess({commit}, payload) {
    return new Promise(function(resolve, reject) {
        commit('setCurrentCRAEntry', payload)
        setTimeout(() => {
            resolve();
          }, "1000")
    })
  },
  
}

const getters = {}



export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
