<template>
    <div class="row">
      <div class="col-12">
        <div class="row">
            <div class="col">
              <b-dropdown :text="yearDropdownText" variant="outline-secondary" size="sm" class="m-md-2">
                <b-dropdown-item v-for="yearCategory in yearCategories" :key="yearCategory.code" @click="changeYearCategory(yearCategory.code, yearCategory.label)">{{ yearCategory.label }}</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="col text-right">
              <b-button 
                variant="outline-secondary"
                size="sm"
                v-b-popover.hover="'Proportion of project-level climate risk ratings across each future timescale.'" >
                <i class="fas fa-question-circle"></i>
              </b-button>
            </div>
        </div>
        
        
        <PieChart
          v-if="dataLoaded"
          :key="seletedYearCategory"
          :chartData="baseChartData"
          :options="options"
          :height="240"
          :style="chartCustomStyles"></PieChart>
      </div>
    </div>
  </template>
  
  <script>
  /*eslint-disable*/
  import DoughnutChart from "./DoughnutChart.vue"
  import PieChart from "./PieChart.vue"
  export default {
    name: "RisksProportionChart",
    components: {
        DoughnutChart,
        PieChart
    },
    props: {
      projCRADetails: Array,
      inputProjectCode: String,
      title: String
    },
    data() {
      return {
        flag: 0,
        dataLoaded: false,
        yearDropdownText: "2030",
        seletedYearCategory: "riskRatingNFCode",
        baseChartData: {
          datasets:[
            {
              fill: true,
              label: "",
              backgroundColor: ["#28a745","#ffc107","#FF9900","#dd4b39"],
              data: [1,1,1,1]
              // data: [0,0,0]
            }
          ],
          labels: [
            "Low",
            "Medium",
            "High",
            "Very High"
          ],
        },
        options: {
          title: {
            text: "Risks proportion by time and rating",
            display: true,
            fontFamily: "Segoe UI",
            fontSize: 20
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue;
                });
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = Math.floor(((currentValue/total) * 100)+0.5);

                return percentage + "%";
              }
            }
          },
          responsive: true,
          // maintainAspectRatio: false
        },
        yearCategories: [
          {
            code: "riskRatingNFCode",
            label: "2030"
          },
          {
            code: "riskRatingMFCode",
            label: "2050"
          },
          {
            code: "riskRatingFFCode",
            label: "2090"
          },
        ]
      }
    },
    computed: {
      chartCustomStyles () {
        return {
          height: `89% !important`,
          position: 'relative'
        }
      }
    },
    methods: {
      changeYearCategory: function(riskYearCode, riskYear){
        console.log(riskYearCode)
        this.seletedYearCategory = riskYearCode
        this.yearDropdownText = riskYear
        this.dataLoaded = false;
        var low = (this.projCRADetails.filter(obj => obj[riskYearCode] == "low")).length;
        var medium = (this.projCRADetails.filter(obj => obj[riskYearCode] == "medium")).length;
        var high = (this.projCRADetails.filter(obj => obj[riskYearCode] == "high")).length;
        var veryHigh = (this.projCRADetails.filter(obj => obj[riskYearCode] == "very_high")).length;
        this.baseChartData.datasets[0].data = [low, medium,high,veryHigh]
        console.log(this.baseChartData.datasets[0].data)
        this.dataLoaded = true;
      },
      setupData: function() {
        var low = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "low")).length;
        var medium = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "medium")).length;
        var high = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "high")).length;
        var veryHigh = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "very_high")).length;
        this.baseChartData.datasets[0].data = [low, medium,high,veryHigh]
        // this.baseChartData.labels.forEach((item,index)=>{
        //   this.baseChartData.labels[index] = item
        // })
        this.options.title.text = this.title
        this.dataLoaded = true;
      },
    },
    
    beforeMount() {
      this.setupData()
      // this.baseChartData.labels.forEach((item,index)=>{
      //   this.baseChartData.labels[index] = item + ": " + (this.projCRADetails.filter(obj => obj.riskRatingNFCode == item)).length
      // })
    }
  }
  </script>
  
  <style lang="css" scoped>
  </style>
  