<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
          <div class="col text-right">
            <b-button 
              variant="outline-secondary"
              size="sm"
              v-b-popover.hover="'Number of risks their ratings before and after adaptation.'" >
              <i class="fas fa-question-circle"></i>
            </b-button>
          </div>
      </div>
        <BarChart
            v-if="dataLoaded"
            :chart-data="baseChartData"
            :options="options"
            :height="300">
        </BarChart>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import BarChart from "./BarChart.vue"
export default {
  name:"RiskRatingAdaptationChart",
  components: {
    BarChart
  },
  props: {
    projCRADetails: Array,
    inputProjectCode: String,
    title: String
  },
  data(){
    return {
      dataLoaded: false,
      baseChartData: {
        datasets: [
          {
            label: 'Low',
            data: [20,50,10],
            backgroundColor: "rgba(40, 167, 69, 0.5)",
            stack: 'Year',
          },
          {
            label: 'Medium',
            data: [20,50,10],
            backgroundColor: "rgba(255, 193, 7, 0.5)",
            stack: 'Year',
          },
          {
            label: "High",
            backgroundColor: "rgba(255, 153, 0, 0.5)",
            data: [20,50,10],
            stack: 'Year'
          },
          {
            label: "Very High",
            backgroundColor: "rgba(221, 75, 57, 0.5)",
            data: [20,50,10],
            stack: 'Year'
          },
          {
            label: 'Residual Low',
            data: [25,40,50],
            backgroundColor: "#28a745",
            stack: 'Residual',
          },
          {
            label: 'Residual Medium',
            data: [25,40,50],
            backgroundColor: "#ffc107",
            stack: 'Residual',
          },
          {
            label: "Residual High",
            backgroundColor: "#FF9900",
            data: [25,40,50],
            stack: 'Residual'
          },
          {
            label: "Residual Very High",
            backgroundColor: "#dd4b39",
            data: [25,40,50],
            stack: 'Residual'
          },
        ],
        labels: [
          "2030",
          "2050",
          "2090",
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true,
                // min: -20,
                stepSize: 1
              },
              stacked: true
            }
          ],
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.7
            }
          ]
        },
        title: {
          // text: "Group Submission Percentage",
          // display: true,
          // fontFamily: "Comfortaa",
          // fontSize: 20
        },
        legend: {
          display: true,
          position: "left",
          labels: {
            fontStyle: 'bold',
            fontFamily: 'Segoe UI'
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            enabled: true,
            // callbacks: {
            //   label: function(tooltipItem, data) {
            //     return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
            //   }
            // }
        }
      },
      colorList: [],
      // monthLabels: [
      // "2030",
      //     "2050",
      //     "2090",
      // ],
      stagingData: {},
      selectedProject: this.inputProjectCode,
      chartLabels: [
        "Low",
        "Medium",
        "High",
        "Very High",
        "Residual Low",
        "Residual Medium",
        "Residual High",
        "Residual Very High"
      ]
    }
  },
  watch: {
    stagingData: function() {
      // const current = this;
      // console.log(this.stagingData);
      // this.stagingData.then(function(result) {
      //   result.forEach(function(object, index) {
      //     var dataSet = current.baseChartData.datasets[index]
      //     dataSet.label = object.groupName

      //     dataSet.data = [];
      //     object.monthItemPercentages.forEach(function(item, index2) {
      //       if (Object.values(item)[0] > 0) {
      //         console.log(Object.values(item)[0]);
      //       }
      //       dataSet.data.push(Object.values(item)[0]);
      //     })
      //   })
      //   current.dataLoaded = true;
      // })
    },
    inputProjectCode: function(newVal, oldVal) {
      this.selectedProject = newVal;
      this.fetchSubmissionCount();
    }
  },
  methods: {
    configureOptions: function() {
      const current = this;
      current.options.title.text = "Asset elements with medium high and extreme risks";
      current.options.title.display = true;
      current.options.title.fontFamily = "Segoe UI"
      current.options.title.fontSize = 20;
    },
    setupData: function() {
        const current = this;
        var NFlow = (current.projCRADetails.filter(obj => obj.riskRatingNFCode == "low")).length;
        var NFmedium = (current.projCRADetails.filter(obj => obj.riskRatingNFCode == "medium")).length;
        var NFhigh = (current.projCRADetails.filter(obj => obj.riskRatingNFCode == "high")).length;
        var NFveryHigh = (current.projCRADetails.filter(obj => obj.riskRatingNFCode == "very_high")).length;

        this.chartLabels.forEach((item,index)=>{
          
          if(this.baseChartData.datasets[index].label == "Low" && this.baseChartData.datasets[index].stack == "Year"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.riskRatingNFCode == "low")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingMFCode == "low")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingFFCode == "low")).length * 100) / 4
            ]
          }
          if(this.baseChartData.datasets[index].label == "Medium" && this.baseChartData.datasets[index].stack == "Year"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.riskRatingNFCode == "medium")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingMFCode == "medium")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingFFCode == "medium")).length * 100) / 4
            ]
          }
          if(this.baseChartData.datasets[index].label == "High" && this.baseChartData.datasets[index].stack == "Year"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.riskRatingNFCode == "high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingMFCode == "high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingFFCode == "high")).length * 100) / 4
            ]
          }
          if(this.baseChartData.datasets[index].label == "Very High" && this.baseChartData.datasets[index].stack == "Year"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.riskRatingNFCode == "very_high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingMFCode == "very_high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.riskRatingFFCode == "very_high")).length * 100) / 4
            ]
          }
          // Residual
          if(this.baseChartData.datasets[index].label == "Residual Low" && this.baseChartData.datasets[index].stack == "Residual"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingNFCode == "low" || (obj.riskRatingNFCode == "low" && obj.residualRiskRatingNFCode == ""))).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingMFCode == "low" || (obj.riskRatingNFCode == "low" && obj.residualRiskRatingNFCode == ""))).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingFFCode == "low" || (obj.riskRatingNFCode == "low" && obj.residualRiskRatingNFCode == ""))).length * 100) / 4
            ]
          }
          if(this.baseChartData.datasets[index].label == "Residual Medium" && this.baseChartData.datasets[index].stack == "Residual"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingNFCode == "medium")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingMFCode == "medium")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingFFCode == "medium")).length * 100) / 4
            ]
          }
          if(this.baseChartData.datasets[index].label == "Residual High" && this.baseChartData.datasets[index].stack == "Residual"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingNFCode == "high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingMFCode == "high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingFFCode == "high")).length * 100) / 4
            ]
          }
          if(this.baseChartData.datasets[index].label == "Residual Very High" && this.baseChartData.datasets[index].stack == "Residual"){
            this.baseChartData.datasets[index].data = [
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingNFCode == "very_high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingMFCode == "very_high")).length * 100) / 4,
              ((current.projCRADetails.filter(obj => obj.residualRiskRatingFFCode == "very_high")).length * 100) / 4
            ]
          }

        })
        this.options.title.text = this.title
        console.log(this.baseChartData.datasets)

        current.dataLoaded = true;
    }
  },
  beforeMount() {
    this.configureOptions();
    this.setupData();
  }
}
</script>

<style>
.small-chart {
  max-width: 550px;
  margin:  8px auto;
  /* height: '500px';
  width: '100%';
  position: 'relative'; */
}
</style>
