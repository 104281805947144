<template>
    <div>
        <div class="row climateContext">
            <div class="col-8 pr-0 pt-2">
                <b-card>
                    <template #header>
                            <h1 class="">{{projectName}}</h1>
                        </template>
                
                <div class="row">
                    <div class="col pl-2 pr-2">
                        <b-card
                            title=""
                            >
                            <b-embed
                                type="iframe"
                                aspect="16by9"
                                :src="dashboardURL"
                                allowfullscreen
                            ></b-embed>
                        </b-card>
                        <b-card
                            title="Asset Map"
                            class="m-2"
                            >
                            <FlexMap mode="2d" :mapSceneId="mapId" id="climateContextMap"/>
                        </b-card>
                    </div>
                </div>
            </b-card>
            </div>
            <div class="col-4 pt-2 pr-4">
                <div class="row mb-2 mt-2">
                    <div class="col-1"></div>
                    <div class="col-3"><h5>Status: </h5></div>
                    <div class="col-8">
                        <b-form-radio-group
                            id="btn-radios-2"
                            v-model="currentStage1Status"
                            button-variant="text-dark"
                            size="sm"
                            name="radio-btn-outline"
                            buttons
                        >
                            <b-form-radio value="" class="btn-outline-danger">Not Started</b-form-radio>
                            <b-form-radio value="in_progress" class="btn-outline-warning">In Progress</b-form-radio>
                            <b-form-radio value="completed" class="btn-outline-success">Completed</b-form-radio>
                        </b-form-radio-group>
                    </div>
                    <!-- <div class="col-6">Status</div>
                    <div class="col-6">
                        <b-form-radio-group
                            v-model="selected"
                            :aria-describedby="ariaDescribedby"
                            name="radio-sub-component"
                        >
                            <b-form-radio value="not_started" class="warning">Not Started</b-form-radio>
                            <b-form-radio value="in_progress">In Progress</b-form-radio>
                            <b-form-radio value="completed">Completed</b-form-radio>
                        </b-form-radio-group>
                    </div> -->
                </div>
                <div class="row">
                    <b-card
                        class=""
                        header-tag="header"
                    >
                        <!-- <template #header>
                            <h2 class="text-center">CRA Configuration</h2>
                        </template> -->
                        <b-card header-tag="header" footer-tag="footer">
                            <template #header>
                                <h2 class="mb-0">Climate Hazard Exposure</h2>
                            </template>
                            <div class="row">
                                <div class="col-12">
                                    <b>
                                        Please check the climate hazards below that have occurred historically at this location or are likely to occur within the project's design life.
                                    </b>
                                    <br>
                                    <br>
                                </div>
                            </div>
                            <b-form-checkbox-group
                                v-model="modifiedClimateCriticalityList"
                                value-field="yes"
                                text-field="label"
                                class="ml-2"
                                stacked
                            >
                                <b-form-checkbox v-for="(item, index) in climateCriticalityList" :key="index" :value="item.label" >
                                    <div class="col-12">
                                        {{item.label}}
                                    </div>
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                            <!-- <b-button href="#" variant="primary">Go somewhere</b-button> -->
                            <template #footer v-if="anyChangesInClimateCriticality">
                                <b-button size="sm" variant="outline-secondary" class="mr-2" @click="resetProjectClimateCriticalityList()">
                                    Cancel
                                </b-button>
                                <b-button size="sm" variant="primary" @click="updateProjectClimateCriticalityList()">
                                    Update
                                </b-button>
                            </template>
                        </b-card>
                        <br>

                        <!-- <b-card header-tag="header" footer-tag="footer">
                            <template #header>
                                <h6 class="mb-0">Climate Criticality TEST</h6>
                            </template>
                            <div class="row" v-for="(item, index) in climateCriticalityList" :key="index">
                                <div class="col-4">

                                    <b-dropdown id="dropdown-buttons" text="RESRT" class="m-2">
                                        <b-dropdown-item-button>Hazard has occured historically and/or is likely to occur within project design life</b-dropdown-item-button>
                                        <b-dropdown-item-button active>Project not directly exposed to this hazard</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                                <div class="col-8">{{item.label}}</div>
                            </div>
                            <b-form-checkbox-group
                                v-model="modifiedClimateCriticalityList"
                                value-field="yes"
                                text-field="label"
                                stacked
                            >
                                <b-form-checkbox v-for="(item, index) in climateCriticalityList" :key="index" :value="item.label" class="col-6">
                                    <small>
                                        {{item.label}}
                                    </small>
                                </b-form-checkbox>
                            </b-form-checkbox-group> 
                            <template #footer v-if="anyChangesInClimateCriticality">
                                <b-button size="sm" variant="outline-secondary" class="mr-2" @click="resetProjectClimateCriticalityList()">
                                    Cancel
                                </b-button>
                                <b-button size="sm" variant="primary" @click="updateProjectClimateCriticalityList()">
                                    Update
                                </b-button>
                            </template>
                        </b-card>
                        <b-card header-tag="header" footer-tag="footer">
                            <template #header>
                                <h6 class="mb-0">Active Climate Hazards</h6>
                            </template>
                            <b-form-checkbox-group
                                v-model="modifiedClimateHazards"
                                value-field="code"
                                text-field="label"
                                stacked
                            >
                                <b-form-checkbox v-for="(item, index) in climateHazards" :key="index" :value="item.code" >{{item.label}}</b-form-checkbox>
                            </b-form-checkbox-group>
                            <template #footer v-if="anyChangesInClimateHazards">
                                <b-button size="sm" variant="outline-secondary" class="mr-2" @click="resetActiveClimateHazards()">
                                    Cancel
                                </b-button>
                                <b-button size="sm" variant="primary" @click="updateActiveClimateHazards()">
                                    Update
                                </b-button>
                            </template>
                        </b-card> -->
                    </b-card>
                </div>
            </div>
        </div>
        <b-modal 
            v-model="isConfirmedModal" 
            ok-title="Yes" 
            ok-variant="outline-success"
            cancel-title="No"
            cancel-variant="outline-danger"
            @cancel="isConfirmedCancel" 
            @hidden="isConfirmedCancel" 
            @ok="isConfirmedOk"
        >
            <b-card >
                Please confirm that Stage 1 is to be "Completed"?
            </b-card>
        </b-modal>
    </div>
</template>

<script>
    /*eslint-disable*/
    import { baseApiUrl } from '../../config'
    import axios from 'axios'
    import FlexMap from './FlexMap.vue'


    export default {
        name: 'ClimateContext',
        components: {
            FlexMap
        },
        data() {
            return {
                modifiedClimateHazards: [],
                anyChangesInClimateHazards: false,
                modifiedClimateCriticalityList: [],
                anyChangesInClimateCriticality: false,
                currentStage1Status: '',
                isConfirmed: false,
                isConfirmedModal: false

            }
        },
        computed: {
            projectName: {
                get(){
                    this.currentStage1Status = JSON.parse(JSON.stringify(this.$store.state.mainModule.currentProject.stage_1_status));
                    return this.$store.state.mainModule.currentProject.name
                }
            },
            climateHazards: {
                get() {
                    this.modifiedClimateHazards = this.$store.state.mapModule.climateHazardList.filter(obj=>obj.isActive == true 
                                                        && this.$store.state.mainModule.currentActiveClimateHazards.includes(obj.code) ).map(item=>item.code)
                    return this.$store.state.mapModule.climateHazardList
                }
            },
            climateCriticalityList: {
                get() {
                    this.modifiedClimateCriticalityList = this.$store.state.mainModule.currentProjectClimateCriticalityList.filter(obj=>obj.yes).map(item=>item.label)
                    return this.$store.state.mainModule.currentProjectClimateCriticalityList
                }
            },
            mapId: {
                get() {
                    return this.$store.state.mainModule.projectMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentProject.code)[0].mapId
                }
            },
            dashboardURL: {
                get() {
                    return this.$store.state.mainModule.projectMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentProject.code)[0].climateDashboardURL
                }
            }
        },
        watch: { 
                modifiedClimateHazards: function(newVal, oldVal) { // watch it
                    let currentActiveClimateHazards = this.$store.state.mapModule.climateHazardList.filter(obj=>obj.isActive == true 
                                                        && this.$store.state.mainModule.currentActiveClimateHazards.includes(obj.code) ).map(item=>item.code)
                    
                    if (this.sameMembers(currentActiveClimateHazards, newVal)) {
                        this.anyChangesInClimateHazards = false
                    }
                    else {
                        this.anyChangesInClimateHazards = true
                    }
                },
                modifiedClimateCriticalityList: function(newVal, oldVal) {
                    let currentClimateCriticality = this.climateCriticalityList.filter(obj=>obj.yes).map(item=>item.label)
                    
                    if (this.sameMembers(currentClimateCriticality, newVal)) {
                        this.anyChangesInClimateCriticality = false
                    }
                    else {
                        this.anyChangesInClimateCriticality = true
                    }
                },
                currentStage1Status: function(newVal, oldVal) {
                    if (newVal == "completed" && newVal != this.$store.state.mainModule.currentProject.stage_1_status && !this.isConfirmed) {
                        this.isConfirmedModal = true
                    }
                    else {
                        if (newVal != this.$store.state.mainModule.currentProject.stage_1_status) {
                            
                            let data = {
                                projectId: this.$store.state.mainModule.currentProjectId,
                                stage: 'stageOne',
                                stageStatus: newVal
                            }
                            let url = baseApiUrl + 'projectStageStatus'
                            let config  = {
                                responseType: 'json'
                            }
                            axios.put(url,data,config).then(()=>{
                                this.$notify({
                                    group: 'successNotifications',
                                    title: "Project Stage One Status Update",
                                    type: 'success',
                                    text: "Update to the project stage one status completed.",
                                    width: 500,
                                    duration: 3000
                                })
                                // update project list and project details
                                this.$store.dispatch('mainModule/fetchProjects').then(()=>{
                                    this.$store.commit('mainModule/setCurrentProject',this.$store.state.mainModule.currentProjectId)
                                    this.isConfirmed = false
                                })
                            })
                            .catch((err)=>{
                                this.$notify({
                                    group: 'errors',
                                    title: "No Update done",
                                    type: 'error',
                                    text: "No Update to the project stage one status completed",
                                    width: 500,
                                    duration: 3000
                                })
                            })
                        }

                    }
                }
        },
        methods: {
            resetActiveClimateHazards() {
                this.modifiedClimateHazards = this.$store.state.mapModule.climateHazardList.filter(obj=>obj.isActive == true 
                                                        && this.$store.state.mainModule.currentActiveClimateHazards.includes(obj.code) ).map(item=>item.code)
            },
            updateActiveClimateHazards() {
                // API CALL TO BACKEND, then REFRESH THE DATA IN THE STORE (Primary Asset Types, available CRA Entries)
                let currentActiveClimateHazards = this.$store.state.mapModule.climateHazardList.filter(obj=>obj.isActive == true 
                                                        && this.$store.state.mainModule.currentActiveClimateHazards.includes(obj.code) ).map(item=>item.code)
                if (!this.sameMembers(this.modifiedClimateHazards,currentActiveClimateHazards)) {
                    let payload = {
                        activeCodes:this.modifiedClimateHazards,
                        projectId: this.$store.state.mainModule.currentProjectId
                    }
                    this.$store.dispatch('mainModule/updateActiveClimateHazards', payload).then(()=>{
                        this.modifiedClimateHazards = this.$store.state.mapModule.climateHazardList.filter(obj=>obj.isActive == true 
                                                        && this.$store.state.mainModule.currentActiveClimateHazards.includes(obj.code) ).map(item=>item.code)
                        this.$notify({
                            group: 'successNotifications',
                            title: "Success",
                            type: 'success',
                            text: "Successfully saved the climate hazard changes",
                            width: 500,
                            duration: 3000
                        })
                        this.$store.dispatch('mapModule/fetchCRAEntries').then(()=>{
                            this.$store.commit("mapModule/setCurrentProjectCRAEntries", this.$store.state.mainModule.currentProjectId)
                            if (this.currentStage1Status == '') {
                                this.currentStage1Status = 'in_progress'
                            }
                        })
                    })
                    
                }
                else {
                    this.$notify({
                        group: 'errors',
                        title: "No Update done",
                        type: 'error',
                        text: "No Update to the Climate Hazards required",
                        width: 500,
                        duration: 3000
                    })
                }
            },

            resetProjectClimateCriticalityList() {
                this.modifiedClimateCriticalityList = this.$store.state.mainModule.currentProjectClimateCriticalityList.filter(obj=>obj.yes).map(item=>item.label)
            },
            updateProjectClimateCriticalityList() {
                // send updated data to back-end
                let data = {
                    projectClimateCriticalityList: this.modifiedClimateCriticalityList,
                    projectId: this.$store.state.mainModule.currentProjectId
                }
                let url = baseApiUrl + 'projectClimateCriticalityList'
                let config  = {
                    responseType: 'json'
                }
                axios.put(url, data, config).then(()=>{
                    this.$notify({
                        group: 'successNotifications',
                        title: "Climate Criticality Update",
                        type: 'success',
                        text: "Update to the Climate Criticality Checklist completed.",
                        width: 500,
                        duration: 3000
                    })
                    this.$store.dispatch("mainModule/fetchProjectClimateCriticalityList", this.$store.state.mainModule.currentProjectId).then(()=>{
                        this.anyChangesInClimateCriticality = false
                        if (this.currentStage1Status == '') {
                            this.currentStage1Status = 'in_progress'
                        }
                        this.$store.dispatch('mapModule/fetchCRAEntries').then(()=>{
                            this.$store.commit("mapModule/setCurrentProjectCRAEntries", this.$store.state.mainModule.currentProjectId)
                            if (this.currentStage1Status == '') {
                                this.currentStage1Status = 'in_progress'
                            }
                        })
                    })
                })

                // update list values
                // advise details via notify
            },

            containsAll: function(arr1, arr2) {
                return arr2.every(arr2Item => arr1.includes(arr2Item))
            },
            sameMembers: function(arr1, arr2) {
                return this.containsAll(arr1, arr2) && this.containsAll(arr2, arr1);
            },
            isConfirmedCancel: function () {
                if (!this.isConfirmed) {
                    setTimeout(() => {
                        this.currentStage1Status = this.$store.state.mainModule.currentProject.stage_1_status
                    }, 300);
                    this.isConfirmed = false
                }
                this.isConfirmedModal = false
            },
            isConfirmedOk: function () {
                setTimeout(() => {
                    this.currentStage1Status = this.$store.state.mainModule.currentProject.stage_1_status
                }, 300);
                this.isConfirmed = true
                this.isConfirmedModal = false
                setTimeout(() => {
                    this.currentStage1Status = "completed"
                }, 300);
            }
        },
        mounted () {
            const current = this
            
        }
    }
</script>

<style scoped>
    .data-scroll {
    overflow: auto;
    max-height: 20vh;
    }
    #climateContextMap {
        height: 75vh;
    }
    #dashboardImg {
        background-image: url("/img/MockDashboard.png");
        background-color: #ffffff;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:center;
        height: 35vh;
        width: 100%;
    }
    .climateContext {
        overflow: auto!important;
    }
    .climateCriticalityTextSpacing {
        margin-left: 2vw;
    }
    .climateCriticalityCheckboxSpacing {
        margin-left: 4vw;
    }
</style>