<template>
  <div id="map" v-bind:style="{cursor: selectedCursor}">
    <div class="mapStyle"></div>
    <!-- widget -->
    <template v-if="webscene">
      <div id="screenshotBtnDiv" class="esri-widget">
        Start to screenshot by clicking the button below:
        <br>
        <br>
        <button
          id="screenshotBtn"
          v-on:click="screenShotMap()"
          class="esri-button"
          aria-label="Select screenshot area"
          title="Select screenshot area">
        Capture
        </button>
      </div>
      <div id="screenshotDiv" class="hide">
        <img class="js-screenshot-image" />
        <div>
          <label>Set a text to be displayed on the image: </label>
          <input type="text" placeholder="Image text" id="textInput" autofocus />
        </div>
        <button
          id="downloadBtn"
          class="btn btn-primary"
          aria-label="Download image"
          title="Download image">
          Download image
        </button>
        <button
          id="closeBtn"
          v-on:click="closeScreenCapture()"
          class="btn btn-secondary"
          aria-label="Back to webscene"
          title="Back to webscene">
          Back to webscene
        </button>
      </div>
      <div id="maskDiv" class="hide screenshotCursor"></div>
      <!-- Slides Widget -->
      <div id="slidesDiv"></div>
      <div id="opacitySliderDiv" class="esri-widget">
        <label><small>Ground Transparency</small></label>
        <div class="row container justify-content-center m-0">
          <div class="col-sm-auto p-0">
            <small>0</small>
          </div>
          <div class="col-sm-auto pl-2 pr-2">
            <input type="range" min="0" max="100" step="1" v-model="opacityValue" @change="setOpacity" class="form-range">
          </div>
          <div class="col-sm-auto p-0">
            <small>100</small>
          </div>
        </div>
      </div>
      <button id="FAQButton"  @click="modalfaqShow = true" class="btn esri-widget custom-faq-widget" title="See FAQ"><i class="fa fa-question-circle"></i></button>
      <b-modal v-model="modalfaqShow" size="lg">
        <!-- <img class="img-fluid" src="sampleImage.jpg" /> -->
        <iframe
          src="./GWH_CollaborationTool_UserGuide.pdf"
          width="100%"
          height="700"
          style="border: none; overflow: hidden;"
        />
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalfaqShow=false"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </template>


  </div>
</template>

<script>
/*eslint-disable*/
// storing MapView/SceneView to state throws error, fix
import { loadModules } from 'esri-loader';
import {baseApiUrl} from "../../config.js"
import axios from "axios"

export default {
  name: 'Map',
  data() {
    return {
      map: {},
      portalURL: "https://au1gis.solutions.arcadis.com/arcgis",
      highlight: null,
      webscene: {},
      view: {},
      screenShotWidget: {},
      selectedCursor: "auto",
      dragHandler: null,
      discussionLayer: null,
      slideWidget: {},
      modalfaqShow: false,
      opacityValue: 50,
      opacitySliderContainer: null,
      expandOpacitySliderWidget: null
    }
  },
  computed: {
    discussionLayerId: {
      get() {
        return this.$store.state.mapModule.staticDiscussionLayerId
      }
    },
  },
  mounted() {
    const current = this;
    loadModules([
      "esri/WebScene",
      "esri/views/SceneView",
      "esri/config",
      "esri/identity/IdentityManager",
      "esri/identity/OAuthInfo",
      "esri/portal/Portal",
      "esri/portal/PortalItem"
    ],
      { css: true })
    .then(([
      WebScene,
      SceneView,
      esriConfig,
      esriId,
      OAuthInfo,
      Portal,
      PortalItem
    ]) => {
      // if (!sessionStorage.origURL) {
      //   sessionStorage.origURL = window.location.href
      // }
      esriConfig.portalUrl = current.portalURL
      var authInfo = new OAuthInfo({
        appId: "8t5iT2yetDHVg8KO",
        portalUrl: current.portalURL,
        flowType: "auto",
        popup: false
      });
      esriId.registerOAuthInfos([authInfo]);

      var portal = new Portal({
        url: current.portalURL
      });
      let portalItem = new PortalItem({
        id: "828233888aa040bb81b46410a3dff086",
        portal: portal
      });
      portal.authMode = "immediate";
      portal.load().then(function(portalResult) {
        var userData = {
          userName: portalResult.user.username,
          email: portalResult.user.email,
          fullName: portalResult.user.fullName
        }
        // current.$store.commit("mapModule/saveUserName", portalResult.user.username)
        current.$store.commit("mapModule/saveUserEmail", portalResult.user.email)
        current.$store.commit("mapModule/saveUser", userData)
      });

      esriId
        .checkSignInStatus(current.portalURL + "/sharing/rest")
        .then(function() {
          current.webscene = new WebScene({
            portalItem: portalItem
          });
          current.view = new SceneView({
            container: "map",
            map: current.webscene,
            highlightOptions: {
              color: [0, 255, 255],
              fillOpacity: 0.6
            }
          })
          current.$store.dispatch("mapModule/fetchDiscussionLayer").then(result=>{
            current.discussionLayer = result
            current.webscene.load()
              .catch((error)=>{
                // error
              })
              .then(function() {
                // remove elevation
                // current.webscene.ground.layers.forEach(function(layer) {
                //   layer.visible = false;
                // });
                current.faqWidget = document.getElementById("FAQButton");
                current.view.ui.add(current.faqWidget,"top-right")
                current.view.map.layers.on("after-add", function(event){
                  if (current.webscene.loaded) {
                    current.$store.commit("mapModule/setMapWebSceneLoadFlag", true)
                  }
                });
                current.$store.commit("mapModule/saveMapWebScene", current.webscene)
                current.view.map.add(current.discussionLayer)
                // current.$store.dispatch("mapModule/fetchDisciplines")
                // current.$store.dispatch("mapModule/fetchUsers")
                current.$store.dispatch("mapModule/fetchPrimaryAssetTypes")
                current.$store.dispatch("mapModule/fetchAssetSubTypes")
                current.$store.dispatch("mapModule/fetchClimateHazards")
                current.$store.dispatch("mapModule/fetchRiskTypes")
                current.$store.dispatch("mapModule/fetchConsequenceCategories")
                current.$store.dispatch("mapModule/fetchRiskOwners")
                current.$store.dispatch("mapModule/fetchMateriality")
                current.$store.dispatch("mapModule/fetchCRAEntries")
                document.getElementById("slidesDiv").style.visibility = "visible";
                document.getElementById("slidesDiv").style.display = "block";
                var slides = current.webscene.presentation.slides.items
                slides.forEach((item, index) => {
                  current.createSlideUI(item, index == 0 ? "first":"")
                });
              })
          })

          // if (window.location.href!=sessionStorage.origURL) {
          //   window.location.href = sessionStorage.origURL
          // }
          current.createWidgets()
      })
      .catch(function(error) {
        esriId.getCredential(current.portalURL + "/sharing/rest");
      });
    });
  },
  methods: {
    createWidgets: function() {
      const current = this
      loadModules([
        "esri/widgets/Home",
        "esri/widgets/LayerList",
        "esri/widgets/Expand",
        "esri/core/watchUtils",
        "esri/widgets/Slider"
      ],{ css: true })
      .then(([
        Home,
        LayerList,
        Expand,
        watchUtils,
        Slider
      ]) => {

        var homeWidget = new Home({
          view: current.view
        });
        var slider = new Slider({
          container: "sliderDiv",
          min: 0,
          max: 100,
          values: [50]
        });
        current.view.ui.add(homeWidget, {
          position: "top-left",
          index: 0
        });
        current.view.ui.add("addDiscussionButton", {
          position: "top-right",
          index: 0
        });

        var layerList = new LayerList({
          view: current.view,
          listItemCreatedFunction: function(event) {
            var item = event.item;
            item.actionsSections = [
              [{
                title: "Zoom to layer",
                className: "fa fa-search",
                id: "full-extent"
              }]
            ]
          }
        });
        layerList.on("trigger-action", function(event) {
          current.view.goTo(event.item.layer.fullExtent);
        });
        // LayerList
        var expandLayerList = new Expand({
          view: current.view,
          expandTooltip: "Layer List",
          collapseTooltip: "Layer List",
          content: layerList,
          group: "top-right"
        })

        current.view.ui.add(expandLayerList, {
          position: "top-right",
          index: 1
        });
        // opacity widget
        document.getElementById("opacitySliderDiv").style.display = "block";
        current.opacitySliderContainer = document.getElementById("opacitySliderDiv");
        current.expandOpacitySliderWidget = new Expand({
          view: current.view,
          expandIconClass: "fa fa-adjust",
          expandTooltip: "Ground transparency",
          collapseTooltip: "Ground transparency",
          content: current.opacitySliderContainer,
          group: "top-right"
        })
        current.view.ui.add(current.expandOpacitySliderWidget, {
          position: "top-right",
          index: 2
        });
        watchUtils.watch(current.expandOpacitySliderWidget, "expanded", function(newValue, oldValue) {
          if (newValue == true) {
            current.opacityValue = 50
          } else {
            current.opacityValue = 1
          }
          current.setOpacity()
        })

        // screenshot
        const screenshotBtnDiv = document.getElementById("screenshotBtnDiv");
        current.screenShotWidget = new Expand({
          view: current.view,
          expandIconClass: "fa fa-camera",
          expandTooltip: "Screen Shot",
          collapseTooltip: "Screen Shot",
          content: screenshotBtnDiv,
          group: "top-right"
        })
        current.view.ui.add(current.screenShotWidget, {
          position: "top-right",
          index: 3
        });

        watchUtils.watch(current.screenShotWidget, "expanded", function(newValue, oldValue) {
          if(current.dragHandler) {
            current.dragHandler.remove();
          }
        })

        // Slides
        const slidesDiv = document.getElementById("slidesDiv");
        current.slideWidget = new Expand({
          view: current.view,
          expanded: true,
          expandIconClass: "fa fa-clone",
          expandTooltip: "Slides",
          collapseTooltip: "Slides",
          content: slidesDiv,
          group: "top-right"
        })
        current.view.ui.add(current.slideWidget, {
          position: "top-right",
          index: 4
        });
        watchUtils.watch(current.slideWidget, "expanded", function(newValue, oldValue) {
          if (newValue == true) {
            document.getElementById("slidesDiv").style.visibility = "visible";
            document.getElementById("slidesDiv").style.display = "block";
          } else {
            document.getElementById("slidesDiv").style.visibility = "hidden";
            document.getElementById("slidesDiv").style.display = "none";
          }
        })

      })
    },
    setOpacity: function() {
      const current = this
      current.webscene.basemap.baseLayers.items.forEach(function(layer) {
          console.log("baseLayers", layer);
          layer.opacity = 1 -(current.opacityValue / 100)
        });
    },
    screenShotMap: function() {
      const current = this
      current.screenShotWidget.collapse()
      current.selectedCursor = "crosshair"
      loadModules(["dojo/on"],{ css: true })
      .then(([on]) => {
        let area = null;
        current.dragHandler = current.view.on("drag", function(event) {
          event.stopPropagation();

          if (event.action !== "end") {
            const xmin = current.clamp(
              Math.min(event.origin.x, event.x),
              0,
              current.view.width
            );
            const xmax = current.clamp(
              Math.max(event.origin.x, event.x),
              0,
              current.view.width
            );
            const ymin = current.clamp(
              Math.min(event.origin.y, event.y),
              0,
              current.view.height
            );
            const ymax = current.clamp(
              Math.max(event.origin.y, event.y),
              0,
              current.view.height
            );
            area = {
              x: xmin,
              y: ymin,
              width: xmax - xmin,
              height: ymax - ymin
            };
            current.setMaskPosition(area);
          } else {
            current.dragHandler.remove();
            current.view
              .takeScreenshot({
                area: area,
                format: "png"
              })
              .then(function(screenshot) {
                current.showPreview(screenshot);

                document.getElementById("downloadBtn").onclick = function() {
                  const text = document.getElementById("textInput").value;
                  if (text) {
                    const dataUrl = current.getImageWithText(screenshot, text);
                    current.downloadImage(
                      current.webscene.portalItem.title + ".png",
                      dataUrl
                    );
                  } else {
                    current.downloadImage(
                      current.webscene.portalItem.title + ".png",
                      screenshot.dataUrl
                    );
                  }
                };

                current.selectedCursor = "auto"
                current.setMaskPosition(null);
              });
          }
        });
      })
    },
    setMaskPosition: function(area){
      const maskDiv = document.getElementById("maskDiv");
      if (area) {
        maskDiv.classList.remove("hide");
        maskDiv.style.left = area.x + "px";
        maskDiv.style.top = area.y + "px";
        maskDiv.style.width = area.width + "px";
        maskDiv.style.height = area.height + "px";
      } else {
        maskDiv.classList.add("hide");
      }
    },
    clamp: function(value, from, to) {
      return value < from ? from : value > to ? to : value;
    },
    showPreview: function(screenshot) {
      const screenshotDiv = document.getElementById("screenshotDiv");
      screenshotDiv.classList.remove("hide");
      const screenshotImage = document.getElementsByClassName(
        "js-screenshot-image"
      )[0];
      screenshotImage.width = screenshot.data.width;
      screenshotImage.height = screenshot.data.height;
      screenshotImage.src = screenshot.dataUrl;
    },
    getImageWithText: function(screenshot, text) {
      const imageData = screenshot.data;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = imageData.height;
      canvas.width = imageData.width;

      context.putImageData(imageData, 0, 0);
      context.font = "20px Arial";
      context.fillStyle = "#000";
      context.fillRect(
        0,
        imageData.height - 40,
        context.measureText(text).width + 20,
        30
      );

      context.fillStyle = "#fff";
      context.fillText(text, 10, imageData.height - 20);

      return canvas.toDataURL();
    },
    downloadImage: function(filename, dataUrl) {
      if (!window.navigator.msSaveOrOpenBlob) {
        const element = document.createElement("a");
        element.setAttribute("href", dataUrl);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        const byteString = atob(dataUrl.split(",")[1]);
        const mimeString = dataUrl
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], {
          type: mimeString
        });
        window.navigator.msSaveOrOpenBlob(blob, filename);
      }
    },
    closeScreenCapture: function() {
      const screenshotDiv = document.getElementById("screenshotDiv");
      screenshotDiv.classList.add("hide");
    },
    createSlideUI: function(slide, placement) {
      const current = this
      var slideElement = document.createElement("div");
      slideElement.id = slide.id;
      slideElement.classList.add("slide");

      var slidesDiv = document.getElementById("slidesDiv");
      if (placement === "first") {
        slidesDiv.insertBefore(slideElement, slidesDiv.firstChild);
      } else {
        slidesDiv.appendChild(slideElement);
      }


      var img = new Image();
      img.src = slide.thumbnail.url;
      img.title = slide.title.text;
      slideElement.appendChild(img);
      slideElement.appendChild(document.createElement("br"));
      var title = document.createElement("div");
      title.innerText = slide.title.text;
      slideElement.appendChild(title);



      slideElement.addEventListener("click", function() {
        var slides = document.querySelectorAll(".slide");
        Array.from(slides).forEach(function(node) {
          node.classList.remove("active");
        });

        slideElement.classList.add("active");

        slide.applyTo(current.view);
      });
    },
    fetchDiscussionLayer: function() {
      const current = this
      return new Promise(function(resolve, reject) {
        const vue = document.getElementById("map").__vue__
        const view = vue.view
        var url = baseApiUrl+"fetchDiscussions"
        var data = {}
        var config = {
          responseType: 'json'
        }
        axios.post(url, data, config).then(response=>{
          var discussionsList = response.data.features
          var discussionsCount = 0;
          loadModules([
            "esri/layers/GraphicsLayer",
            "esri/Graphic"
          ],{ css: true })
          .then(([
            GraphicsLayer,
            Graphic
          ]) => {
            const discussionGraphicsLayer = new GraphicsLayer({
              id: current.discussionLayerId
            });
            for (const discussion of discussionsList) {
              var discussionEntry = new Graphic({
                geometry: {
                  type: "point",
                  latitude: discussion.geometry.latitude,
                  longitude:discussion.geometry.longitude,
                  z: discussion.geometry.height
                },
                attributes: discussion.attributes,
                symbol: {
                  type: "point-3d",
                  symbolLayers: [{
                    type: "object",
                    anchor: "origin",
                    width: 5,
                    material: {
                      color: [0, 132, 168, 1]
                    }
                  }]
                }
              });
              discussionGraphicsLayer.graphics.push(discussionEntry)
            }

            resolve(discussionGraphicsLayer)
          })
        })
      });
    },
  },
  beforeDestroy() {
    if (this.view) {
      // destroy the map view
      this.view.container = null;
    }
  },
};

</script>
<style scoped>
#screenshotBtnDiv {
  padding: 10px;
  width: 260px;
  bottom: 15px;
  right: 10px;
  font-size: 14px;
}
#maskDiv {
  position: absolute;
  background: rgba(255, 51, 0, 0.1);
  border: 2px dashed rgb(255, 51, 0);
}
#screenshotDiv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
#screenshotDiv > * {
  margin: 0.5em;
}
.hide {
  display: none;
}
/* Slides */
#slidesDiv {
  position: relative;
  opacity: 0.9;
  padding-right: 10px;
  visibility: hidden;
  /* bottom: 15px; */
  left: 0;
  right: 0;
  text-align: center;
  /* width: 74.8vw; */
  max-width:100%;
  display: none;
  overflow-x: auto;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.2);
}
.slide.active {
   background-color: rgba(0, 121, 193, 0.5);
}

#slidesDiv .slide {
  /* Show cursor as pointer when on a slide */
  cursor: pointer;
  margin-bottom: 6px;
}

#slidesDiv .slide .title {
  /* Center the title text */
  text-align: center;
}
/* Draw active slide with a nice border around the thumbnail */

#slidesDiv .slide.active img {
  box-shadow: 0px 0px 12px black;
  border-style: solid;
  border-width: thin;
  border-color: black;
}

div.scrollmenu {
  background-color: #333;
  /* overflow: auto; */
  overflow-x: auto;
  white-space: nowrap;
}

#slidesDiv div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 12px;
}

#slidesDiv::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #333;
}

#slidesDiv::-webkit-scrollbar
{
	width: 2px !important;
	background-color: #333;
}

#slidesDiv::-webkit-scrollbar-thumb
{
	background-color: #6c757d;
	/* border: 2px solid #555555; */
}
#opacitySliderDiv {
  /* background-color: #fff; */
  padding: 10px;
  display: none;
  width: 250px;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
      overflow: hidden;
      width: 150px;
      -webkit-appearance: none;
      background-color: #E66200;
      border-radius: 8px;
    }

    input[type='range']::-webkit-slider-runnable-track {
      height: 15px;
      -webkit-appearance: none;
      color: #13bba4;
      margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 15px;
      -webkit-appearance: none;
      height: 15px;
      cursor: ew-resize;
      background: #434343;
      box-shadow: -150px 0 0 150px #ffe2cc;
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #ffe2cc;
}
input[type="range"]::-moz-range-track {
  background-color: #E66200;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #ffe2cc;
}
input[type="range"]::-ms-fill-upper {
  background-color: #E66200;
}
</style>

<style scoped>
#map, .mapStyle {
  min-height: 94.5vh;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 94.5vh;
}
.custom-faq-widget {
    z-index: 1;
    /* display: none; */
    padding: 0.175rem 0.45rem;
  }
</style>
