/*eslint-disable*/
import Vue from "vue";
import Router from "vue-router";
import Landing from "./components/Landing.vue";
import ProgramReportingDashboard from "./components/ProgramReportingDashboard.vue";
import ProjectReportingDashboard from "./components/ProjectReportingDashboard.vue";
import AssetContext from "./components/AssetContext.vue";
import ClimateContext from "./components/ClimateContext.vue";
import PreliminaryAssessment from "./components/PreliminaryAssessment.vue";
import DetailedAssessment from "./components/DetailedAssessment.vue";
import AdaptationPlanning from "./components/AdaptationPlanning.vue";
import AppHeader from "./layout/AppHeader.vue";

Vue.use(Router);




let router = new Router({
    linkExactActiveClass: "active",
    mode: 'history',
    routes: [
      {
        path: "/",
        name: "landing",
        components: {
          header: AppHeader,
          default: Landing,
          footer: ""
        }
      },
      {
        path: "/programreportingdashboard",
        name: "programreportingdashboard",
        components: {
          header: AppHeader,
          default: ProgramReportingDashboard,
          footer: ""
        }
      },
      {
        path: "/projectreportingdashboard",
        name: "projectreportingdashboard",
        components: {
          header: AppHeader,
          default: ProjectReportingDashboard,
          footer: ""
        }
      },
      {
        path: "/assetcontext",
        name: "assetcontext",
        components: {
          header: AppHeader,
          default: AssetContext,
          footer: ""
        }
      },
      {
        path: "/climatecontext",
        name: "climatecontext",
        components: {
          header: AppHeader,
          default: ClimateContext,
          footer: ""
        }
      },
      {
        path: "/preliminaryassessment",
        name: "preliminaryassessment",
        components: {
          header: AppHeader,
          default: PreliminaryAssessment,
          footer: ""
        }
      },
      {
        path: "/detailedassessment",
        name: "detailedassessment",
        components: {
          header: AppHeader,
          default: DetailedAssessment,
          footer: ""
        }
      },
      {
        path: "/adaptationplanning",
        name: "adaptationplanning",
        components: {
          header: AppHeader,
          default: AdaptationPlanning,
          footer: ""
        }
      }
    ]
  });

//   router.beforeResolve((to, from, next) => {
//     // console.log(to);
//     // console.log(from);
//     // next();
//     // if(to.path.includes('/access_token')) {
//     //     sessionStorage.esriJSAPIOAuth = localStorage.esriJSAPIOAuth
//     //     next({name: 'landing'})
//     // }
//     // else {
//         next()
//     // }
//     // var isStageNavPath = sessionStorage.getItem("isStagePath")?true:false
//     // var defaultTabs = [
//     //     '/',
//     //     '/landing',
//     //     '/programreportingdashboard',
//     //     '/projectreportingdashboard'
//     // ]
//     // if(!defaultTabs.includes(to.path)){
//     //     // this.$store.commit('mainModule/resetStageNav')
//     //     sessionStorage.setItem("isStagePath", true)
//     // }
//     // else {
//     //   sessionStorage.setItem("isStagePath", false)
//     //   next();
//     // }
//   })
  export default router;