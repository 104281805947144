<script>
/*eslint-disable*/
import { Bar, mixins } from 'vue-chartjs'
export default {
  name: "BarChart",
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['options'],
  watch: {
    chartData: function() {
      // console.log("watcher update data activated");
      this.$data._chart.update();
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}

</script>
