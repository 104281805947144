<template>
    <div class="row">
      <div class="col-12">
        <PieChart
          :chartData="baseChartData"
          :options="options"
          :height="200"
          :flag="flag"></PieChart>
      </div>
    </div>
  </template>
  
  <script>
  /*eslint-disable*/
  import PieChart from "./PieChart.vue"
  export default {
    name: "NoRisksAAIChart",
    components: {
      PieChart
    },
    props: {
      projectCRAEntryList: Array,
      // title: String
    },
    data() {
      return {
        flag: 0,
        defaultBaseChartData: {
          datasets:[
            {
              fill: true,
              label: "",
              backgroundColor: ["#ffc107","#FF9900","#dd4b39"],
              data: [0,0,0]
            }
          ],
          labels: [
            "medium",
            "high",
            "very_high"
          ],
        },
        baseChartData: {
          datasets:[
            {
              fill: true,
              label: "",
              backgroundColor: ["#ffc107","#FF9900","#dd4b39"],
              data: [1,1,1]
              // data: [0,0,0]
            }
          ],
          labels: [
            "medium",
            "high",
            "very_high"
          ],
        },
        options: {
          title: {
            text: "Number of risks with adaptation actions implemented",
            display: true,
            fontFamily: "Segoe UI",
            fontSize: 20
          },
          legend: {
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          tooltips: {
            enabled: false
          }
        }
      }
    },
    computed: {
      // bschart: {
      //   get(){
      //     let bschart = this.defaultBaseChartData
      //     console.log("here")
      //     console.log("medium",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length)
      //     console.log("high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length)
      //     console.log("very_high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length)
      //     var medium = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length;
      //     var high = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length;
      //     var veryHigh = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length;
      //     bschart.datasets[0].data = [medium,high,veryHigh]
      //     // this.options.title.text = ""
      //     bschart.labels.forEach((item,index)=>{
      //       bschart.labels[index] = item + ": " + (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == item)).length
      //     })
      //     return bschart
      //   }
      // }
    },
    watch: {
      projectCRAEntryList(newValue) {
          // console.log("watch",newValue)
          this.projectCRAEntryList = newValue
          // const current = this;
          // console.log("here")
          // console.log("medium",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length)
          // console.log("high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length)
          // console.log("very_high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length)
          var medium = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length;
          var high = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length;
          var veryHigh = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length;
          this.baseChartData.datasets[0].data = [medium,high,veryHigh]
          this.options.title.text = ""
          this.baseChartData.labels.forEach((item,index)=>{
            this.baseChartData.labels[index] = item + ": " + (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == item)).length
          })
      },
    },
    // computed: {
    //   currentProjectCRAEntryList: {
    //       get() {
    //         return this.$store.state.mapModule.currentProjectCRAEntryList
              
    //       }
    //   },
    // },
    beforeMount() {
  
      // const current = this;
      // console.log("here")
      // console.log("medium",(current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length)
      // console.log("high",(current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length)
      // console.log("very_high",(current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length)
      // var medium = (current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length;
      // var high = (current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length;
      // var veryHigh = (current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length;
      // current.baseChartData.datasets[0].data = [medium,high,veryHigh]
      // current.options.title.text = ""
      // current.baseChartData.labels.forEach((item,index)=>{
      //   current.baseChartData.labels[index] = item + ": " + (current.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == item)).length
      // })
  
    }
  }
  </script>
  
  <style lang="css" scoped>
  </style>
  