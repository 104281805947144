<template lang="html">
  
  <div class="row">
    <div class="col-12">
      <div class= "small-chart">
        <br/>
        <BarChart
            
            :chart-data="baseChartData"
            :options="options"
            :height="400">
        </BarChart>
        </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import BarChart from "./BarChart.vue"
export default {
  name:"HighRiskChart",
  components: {
    BarChart
  },
  props: {
    inputProjectCode: Number
  },
  data(){
    return {
      dataLoaded: false,
      baseChartData: {
        datasets: [{
          label: "Medium",
          backgroundColor: "#ffc107",
          data: new Array(12).fill(100)
        },
        {
          label: "High",
          backgroundColor: "#FF9900",
          data: new Array(12).fill(50)
        },
        {
          label: "Very High",
          backgroundColor: "#dd4b39",
          data: new Array(12).fill(20)
        },],
        labels: [
          "Pavements",
          "Bridges and Viaducts",
          "Drainage Structures",
          "Retaining Walls"
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true,
                // min: -20,
                stepSize: 1
              },
              stacked: true
            }
          ],
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.7
            }
          ]
        },
        title: {
          // text: "Group Submission Percentage",
          // display: true,
          // fontFamily: "Comfortaa",
          // fontSize: 20
        },
        responsive: true,
        maintainAspectRatio: false
      },
      colorList: [],
      monthLabels: [
        "Pavements",
        "Bridges and Viaducts",
        "Drainage Structures",
        "Retaining Walls"
      ],
      stagingData: {},
      selectedProject: this.inputProjectCode
    }
  },
  watch: {
    stagingData: function() {
      // const current = this;
      // console.log(this.stagingData);
      // this.stagingData.then(function(result) {
      //   result.forEach(function(object, index) {
      //     var dataSet = current.baseChartData.datasets[index]
      //     dataSet.label = object.groupName

      //     dataSet.data = [];
      //     object.monthItemPercentages.forEach(function(item, index2) {
      //       if (Object.values(item)[0] > 0) {
      //         console.log(Object.values(item)[0]);
      //       }
      //       dataSet.data.push(Object.values(item)[0]);
      //     })
      //   })
      //   current.dataLoaded = true;
      // })
    },
    inputProjectCode: function(newVal, oldVal) {
      this.selectedProject = newVal;
      this.fetchSubmissionCount();
    }
  },
  methods: {
    configureOptions: function() {
      const current = this;
      current.options.title.text = "Asset elements with medium high and extreme risks";
      current.options.title.display = true;
      current.options.title.fontFamily = "Segoe UI"
      current.options.title.fontSize = 24;
    },
    setupData: function() {
      // edit to fetch data from database
        const current = this;
        var projectCraEntryList = this.$store.state.mapModule.craEntryList.filter(obj=>{return obj.project.code == current.selectedProject})[0].entries
        
        projectCraEntryList.forEach((object, index) => {
          var dataSet = current.baseChartData.datasets[index]
        //   dataSet.label = object.climateHazardLabel
        //   object.data.forEach((item, index2)=>{
        //     dataSet.data[index2] = item.count;
        //   })
        })
        current.dataLoaded = true;

        //   var url = baseApiUrl + "getEnergyCategorySubmissionCountData"
    //   var data = {
    //     year: current.selectedYear,
    //     work_package_id: current.selectedWorkPackage,
    //     userToken: sessionStorage.userToken
    //   }
    //   var config = {
    //     responseType: 'json'
    //   }
    //   var dataSetStaging = [];
    //   axios.post(url, data, config).then((result) => {
    //     const resultData = result.data;
    //     resultData.forEach((object, index) => {
    //       var dataSet = current.baseChartData.datasets[index]
    //       dataSet.label = object.name
    //       object.data.forEach((item, index2)=>{
    //         dataSet.data[index2] = item.count;
    //       })
    //     })
    //     current.dataLoaded = true;
    //   });
    },
    fetchSubmissionCount: function() {
      const current = this
      current.dataLoaded = false
      current.setupData();
    }
  },
  beforeMount() {
    this.configureOptions();
    // this.setupData();
  }
}
</script>

<style>
.small-chart {
  max-width: 550px;
  margin:  8px auto;
  /* height: '500px';
  width: '100%';
  position: 'relative'; */
}
</style>
