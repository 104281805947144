<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
          <div class="col">
            <b-dropdown :text="yearDropdownText" variant="outline-secondary" size="sm" class="m-md-2">
              <b-dropdown-item v-for="yearCategory in yearCategories" :key="yearCategory.code" @click="changeYearCategory(yearCategory.code, yearCategory.label)">{{ yearCategory.label }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col text-right">
            <b-button 
              variant="outline-secondary"
              size="sm"
              v-b-popover.hover="'Summary of risk ratings associated with each key climate change hazard.'" >
              <i class="fas fa-question-circle"></i>
            </b-button>
          </div>
      </div>
      <DoughnutChart
        v-if="dataLoaded"
        :key="seletedYearCategory"
        :chartData="baseChartData"
        :options="options"
        :height="240"
        :style="chartCustomStyles"></DoughnutChart>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import DoughnutChart from "./DoughnutChart.vue"
export default {
  name: "ClimateHazardsMediumToExtremeChartTest",
  components: {
      DoughnutChart
  },
  props: {
    projCRADetails: Array,
    inputProjectCode: String,
    title: String
  },
  data() {
    return {
      flag: 0,
      dataLoaded: false,
      yearDropdownText: "2030",
      seletedYearCategory: "riskRatingNFCode",
      baseChartData: {
        datasets:[
          {
            fill: true,
            label: "",
            backgroundColor: [
              "#f66d9b",
              "#9561e2",
              "#6574cd",
              "#e3342f",
              "#f6993f",
              "#ffed4a",
              "#38c172",
              "#4dc0b5",
              "#3490dc"
            ],
            data: [1,1,1]
            // data: [0,0,0]
          }
        ],
        labels: [
          'Heatwaves',
          'Drought periods',
          'Maximum temperatures',
          'Compounded extreme events',
          'Flooding',
          'Bushfire weather',
          'Extreme wind',
          'Rainfall intensity',
          'Sea level rise and storm surge'
        ],
      },
      options: {
        title: {
          text: "Risks proportion by time and rating",
          display: true,
          fontFamily: "Segoe UI",
          fontSize: 20
        },
        legend: {
          display: true,
          position: "left",
          labels: {
            fontStyle: 'bold',
            fontFamily: 'Segoe UI'
          }
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                return previousValue + currentValue;
              });
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);

              return data['labels'][tooltipItem['index']] + ":" + percentage + "%";
            }
          }
        },
        responsive: true,
        // maintainAspectRatio: false
      },
      yearCategories: [
        {
          code: "riskRatingNFCode",
          label: "2030"
        },
        {
          code: "riskRatingMFCode",
          label: "2050"
        },
        {
          code: "riskRatingFFCode",
          label: "2090"
        },
      ]
    }
  },
  computed: {
    chartCustomStyles () {
      return {
        height: `89% !important`,
        position: 'relative'
      }
    }
  },
  methods: {
    changeYearCategory: function(riskYearCode, riskYear){
      console.log(riskYearCode)
      this.seletedYearCategory = riskYearCode
      this.yearDropdownText = riskYear
      this.dataLoaded = false;
      var medium = (this.projCRADetails.filter(obj => obj[riskYearCode] == "medium")).length;
      var high = (this.projCRADetails.filter(obj => obj[riskYearCode] == "high")).length;
      var veryHigh = (this.projCRADetails.filter(obj => obj[riskYearCode] == "very_high")).length;

      var heatwaves = (this.projCRADetails.filter(obj => obj.climateHazardCode == "heatwaves" &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var drought = (this.projCRADetails.filter(obj => obj.climateHazardCode == "drought"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var maximum_temperatures = (this.projCRADetails.filter(obj => obj.climateHazardCode == "maximum_temperatures"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var extreme_events = (this.projCRADetails.filter(obj => obj.climateHazardCode == "extreme_events"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var flooding = (this.projCRADetails.filter(obj => obj.climateHazardCode == "flooding"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var bushfire = (this.projCRADetails.filter(obj => obj.climateHazardCode == "bushfire"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var wind = (this.projCRADetails.filter(obj => obj.climateHazardCode == "wind"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var rainfall = (this.projCRADetails.filter(obj => obj.climateHazardCode == "rainfall"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;
      var sea_level_storm_surge = (this.projCRADetails.filter(obj => obj.climateHazardCode == "sea_level_storm_surge"  &&
                      (obj[riskYearCode] == "medium" || 
                      obj[riskYearCode] == "high" ||
                      obj[riskYearCode] == "very_high"
                      )
                       )).length;

      this.baseChartData.datasets[0].data = [
        heatwaves,
        drought,
        maximum_temperatures,
        extreme_events,
        flooding,
        bushfire,
        wind,
        rainfall,
        sea_level_storm_surge
      ]
      console.log(this.baseChartData.datasets[0].data)
      this.dataLoaded = true;
    },
    setupData: function() {
      var medium = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "medium")).length;
      var high = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "high")).length;
      var veryHigh = (this.projCRADetails.filter(obj => obj[this.seletedYearCategory] == "very_high")).length;

      var heatwaves = (this.projCRADetails.filter(obj => obj.climateHazardCode == "heatwaves" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var drought = (this.projCRADetails.filter(obj => obj.climateHazardCode == "drought" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var maximum_temperatures = (this.projCRADetails.filter(obj => obj.climateHazardCode == "maximum_temperatures" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var extreme_events = (this.projCRADetails.filter(obj => obj.climateHazardCode == "extreme_events" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var flooding = (this.projCRADetails.filter(obj => obj.climateHazardCode == "flooding" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var bushfire = (this.projCRADetails.filter(obj => obj.climateHazardCode == "bushfire" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var wind = (this.projCRADetails.filter(obj => obj.climateHazardCode == "wind" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var rainfall = (this.projCRADetails.filter(obj => obj.climateHazardCode == "rainfall" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;
      var sea_level_storm_surge = (this.projCRADetails.filter(obj => obj.climateHazardCode == "sea_level_storm_surge" &&
                      (obj[this.seletedYearCategory] == "medium" || 
                      obj[this.seletedYearCategory] == "high" ||
                      obj[this.seletedYearCategory] == "very_high"
                      )
                       )).length;

      this.baseChartData.datasets[0].data = [
        heatwaves,
        drought,
        maximum_temperatures,
        extreme_events,
        flooding,
        bushfire,
        wind,
        rainfall,
        sea_level_storm_surge
      ]


      this.options.title.text = this.title
      this.dataLoaded = true;
    },
  },
  
  beforeMount() {
    this.setupData()
    // this.baseChartData.labels.forEach((item,index)=>{
    //   this.baseChartData.labels[index] = item + ": " + (this.projCRADetails.filter(obj => obj.riskRatingNFCode == item)).length
    // })
  }
}
</script>

<style lang="css" scoped>
</style>
