<template>
  <div>
    <div class="row prelimAssessmentPage">
      <div class="col-8">
        <b-card
            title=""
            class="m-2 p-0"
            no-body>
            <template #header>
              <h1 class="mb-0">{{projectName.toUpperCase()}}</h1>
            </template>
            
        </b-card>
      </div>
      <div class='col-2'>
        <div class="row">
            <div class="col-2 mt-4 pt-3 mb-4">
              <b-button variant="outline-primary" size="sm" v-b-tooltip.hover.bottom title="Fill Attendees" v-b-modal.modal-center @click="openAttendeesModalFlag = true"><i class="fa fa-address-card"></i></b-button>
            </div>
            <div class="col-4 text-center">
                <h5 class="mt-4 pt-3 mb-4">Status: </h5>
            </div>
            <div class="col-6 mt-2 mb-2">
                <b-form-radio-group
                    id="btn-radios-2"
                    v-model="currentStage2Status"
                    button-variant="text-dark"
                    size="sm"
                    name="radio-btn-outline"
                    buttons
                    stacked
                >
                    <b-form-radio value="" class="btn-outline-danger">Not Started</b-form-radio>
                    <b-form-radio value="in_progress" class="btn-outline-warning">In Progress</b-form-radio>
                    <b-form-radio value="completed" class="btn-outline-success">Completed</b-form-radio>
                </b-form-radio-group>
            </div>
        </div>
      </div>
      <div class="col-2">
        <b-card
            title=""
            class="m-2 p-0">
            <template #header>
              <h6 class="mb-0">RISK COUNTER</h6>
            </template>
            <b-card-body class="p-0">
              <div class="d-flex justify-content-end">
                <h3 class="p-1">
                  <b-badge variant="success">{{ riskCounterCRA.countLow }}</b-badge>
                </h3>
                <h3 class="p-1">
                  <b-badge variant="warning">{{ riskCounterCRA.countMedium }}</b-badge>
                </h3>
                <h3 class="p-1">
                  <b-badge class="badge-orange">{{ riskCounterCRA.countHigh }}</b-badge>
                </h3>
                <h3 class="p-1">
                  <b-badge variant="danger">{{ riskCounterCRA.countVeryHigh }}</b-badge>
                </h3>
              </div>

            </b-card-body>
            
        </b-card>
      </div>
    </div>
    <b-table-simple sticky-header small responsive>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <b-thead head-variant="light" class="testSticky">
        <b-tr>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Asset Type</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Risk Description</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Climate Hazards</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Phase</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Control measures currently in the design</b-th>
          <b-th class="table-th-border-custom text-center" colspan="3">2030</b-th>
          <b-th class="table-th-border-custom text-center" colspan="3">2050</b-th>
          <b-th class="table-th-border-custom text-center" colspan="3">2090</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Owner</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Stakeholders</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="2">Actions</b-th>
        </b-tr>
        <b-tr>
          <b-th class="table-th-border-custom">Likelihood</b-th>
          <b-th class="table-th-border-custom">Consequence</b-th>
          <b-th class="table-th-border-custom">Risk Score</b-th>

          <b-th class="table-th-border-custom">Likelihood</b-th>
          <b-th class="table-th-border-custom">Consequence</b-th>
          <b-th class="table-th-border-custom">Risk Score</b-th>

          <b-th class="table-th-border-custom">Likelihood</b-th>
          <b-th class="table-th-border-custom">Consequence</b-th>
          <b-th class="table-th-border-custom">Risk Score</b-th>
        </b-tr>
        <b-tr>
          <!-- <b-th>
            <button class="btn btn-outline-secondary btn-sm" type="button" v-on:click="clearAllFilters()"><i class="fa fa-eraser" ></i></button>
          </b-th> -->
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedPrimaryAssetType">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in primaryAssetTypeFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <input class="form-control form-control-sm" type="text" v-model="riskDescriptionString">
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedClimateHazard">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in climateHazardFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedPhase">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in phaseFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <input class="form-control form-control-sm" type="text" v-model="designControlMeasureString">
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedNFLikelihood">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentLikelihoodFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedNFConsequence">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentConsequenceFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedNFRiskScore">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentRatingFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedMFLikelihood">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentLikelihoodFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedMFConsequence">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentConsequenceFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedMFRiskScore">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentRatingFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedFFLikelihood">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentLikelihoodFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedFFConsequence">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentConsequenceFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedFFRiskScore">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentRatingFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <input class="form-control form-control-sm" type="text" v-model="ownerString">
          </b-th>
          <b-th>
            <input class="form-control form-control-sm" type="text" v-model="stakeholdersString">
          </b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="filteredList.length">
          <b-tr v-for="item in craEntryListFinal" :key="item.objectId">
            <b-td>
              <select class="form-control" v-model="item.primaryAssetTypeCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="primaryAssetType in primaryAssetTypeOptions"  :key="primaryAssetType.id" :value="primaryAssetType.code">{{primaryAssetType.label}}</option>
              </select>
              <span v-else>{{ item.primaryAssetTypeLabel }}</span>
            </b-td>
            <b-td>
              <textarea v-if="item.isEdit" style="resize:none;" rows="2" class="form-control" v-model="item.risk_description"></textarea>
              <span v-else>{{ item.risk_description }}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.climateHazardCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="climateHazard in climateHazardOptions"  :key="climateHazard.id" :value="climateHazard.code">{{climateHazard.label}}</option>
              </select>
              <span v-else>{{ item.climateHazardLabel }}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.riskOwnerCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="phase in phaseOptions"  :key="phase.id" :value="phase.code">{{phase.label}}</option>
              </select>
              <span v-else>{{ item.riskOwnerLabel }}</span>
            </b-td>
            <b-td>
              <textarea v-if="item.isEdit" style="" rows="2" class="form-control" v-model="item.current_design_control_measure"></textarea>
              <span v-else>{{ item.current_design_control_measure }}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.riskAssessmentLikelihoodNFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentLikelihood in assessmentLikelihoodOptions"  :key="assessmentLikelihood.id" :value="assessmentLikelihood.code">{{assessmentLikelihood.label}}</option>
              </select>
              <span v-else>{{item.riskAssessmentLikelihoodNFLabel}}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.riskAssessmentConsequenceNFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentConsequenceOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.riskAssessmentConsequenceNFLabel}}</span>
            </b-td>
            <b-td>
              <!-- <select class="form-control" v-model="item.riskRatingNFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentRatingOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.riskRatingNFLabel}}</span> -->                                                                                  
              <span :class="'text-'+(item.riskRatingNFCode=='very_high'?'danger':(item.riskRatingNFCode=='high'?'orange':(item.riskRatingNFCode=='medium'?'warning':(item.riskRatingNFCode=='low'?'success':'secondary'))))">{{item.riskRatingNFLabel}}</span>
            </b-td>
            <!-- mf -->
            <b-td>
              <select class="form-control" v-model="item.riskAssessmentLikelihoodMFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentLikelihood in assessmentLikelihoodOptions"  :key="assessmentLikelihood.id" :value="assessmentLikelihood.code">{{assessmentLikelihood.label}}</option>
              </select>
              <span v-else>{{item.riskAssessmentLikelihoodMFLabel}}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.riskAssessmentConsequenceMFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentConsequenceOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.riskAssessmentConsequenceMFLabel}}</span>
            </b-td>
            <b-td>
              <!-- <select class="form-control" v-model="item.riskRatingMFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentRatingOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select> -->
              <!-- <span v-else>{{item.riskRatingMFLabel}}</span> -->
              <span :class="'text-'+(item.riskRatingMFCode=='very_high'?'danger':(item.riskRatingMFCode=='high'?'orange':(item.riskRatingMFCode=='medium'?'warning':(item.riskRatingMFCode=='low'?'success':'secondary'))))">{{item.riskRatingMFLabel}}</span>
            </b-td>
            <!-- ff -->
            <b-td>
              <select class="form-control" v-model="item.riskAssessmentLikelihoodFFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentLikelihood in assessmentLikelihoodOptions"  :key="assessmentLikelihood.id" :value="assessmentLikelihood.code">{{assessmentLikelihood.label}}</option>
              </select>
              <span v-else>{{item.riskAssessmentLikelihoodFFLabel}}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.riskAssessmentConsequenceFFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentConsequenceOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.riskAssessmentConsequenceFFLabel}}</span>
            </b-td>
            <b-td>
              <!-- <select class="form-control" v-model="item.riskRatingFFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentRatingOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.riskRatingFFLabel}}</span> -->
              <span :class="'text-'+(item.riskRatingFFCode=='very_high'?'danger':(item.riskRatingFFCode=='high'?'orange':(item.riskRatingFFCode=='medium'?'warning':(item.riskRatingFFCode=='low'?'success':'secondary'))))">{{item.riskRatingFFLabel}}</span>
            </b-td>
            <b-td>
              <textarea v-if="item.isEdit" style="resize:none;" rows="2" class="form-control" v-model="item.assigned_owner"></textarea>
              <span v-else>{{ item.assigned_owner }}</span>
            </b-td>
            <b-td>
              <textarea v-if="item.isEdit" style="resize:none;" rows="2" class="form-control" v-model="item.stakeholders"></textarea>
              <span v-else>{{ item.stakeholders }}</span>
            </b-td>

            <b-td>
              <div class="d-flex justify-content-end">
                  <span :class="'p-1 '+(item.isEdit==true?'hideCustom':'')">
                    <b-button variant="outline-primary" size="sm" @click="editRowHandler(item)" v-b-tooltip.hover.left title="Edit entry">
                      <i class="fa fa-pen"></i>
                    </b-button>
                  </span>
                  <span class="p-1" v-if="item.isEdit">
                    <b-button variant="outline-danger" size="sm" v-b-tooltip.hover.left title="Cancel edit entry" @click="editRowHandler(item)"><i class="fa fa-times"></i></b-button>
                  </span>
                  <span class="p-1" v-if="item.isEdit">
                    <b-button variant="outline-success" size="sm" v-b-tooltip.hover.bottom title="Save entry" @click="saveRowHandler(item)"><i class="fa fa-save"></i></b-button>
                  </span>
                  <span class="p-1">
                    <b-button variant="outline-danger" size="sm" @click="deleteRowHandler(item)" v-b-tooltip.hover.bottom title="Delete entry"><i class="fa fa-trash"></i></b-button>
                  </span>
              </div>
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr>
            <div class="">
              No data found.
            </div>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot>
        
      </b-tfoot>
    </b-table-simple>
    <div class="pr-5 pl-3">
      <paginate
        v-if="filteredList.length > 0"
        v-model="selectedPageNumber"
        :page-count="numberOfPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="selectNewPage"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
      >
      </paginate>
      
    </div>
    <button type="button" class="btn btn-secondary btn-circle btn-xl bottomright btn-orange" v-on:click="exportReport()" title="Export Report"><i class="fa fa-download"></i></button>
    <b-modal 
      v-model="openAttendeesModalFlag" 
      size="lg" 
      :title='stageName+": Stakeholder Engagement Summary"' 
      title-tag="h4"
      @hidden="cancelAttendees">

      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Attendees</label>
        <div class="col-sm-8">
          <textarea rows="3" class="form-control" v-model="textAttendees" required></textarea>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-end">
          <span class="p-1">
            <b-button
              variant="outline-primary"
              size="sm"
              class="float-right"
              @click="addAttendee()">
              Add
            </b-button>
          </span>
        </div>
      <br>
      <b-table-simple sticky-header small responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Attendees</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="stageAttendeesList.length > 0 || projectAssessmentStakeholdersList.length > 0">
            <tr v-for="(stageItem, stageIndex) in stageAttendeesList" :key="stageIndex">
              <td>{{stageItem.created_at?new Date(stageItem.created_at).toISOString().substring(0,10):stageItem.date}}</td>
              <td>{{stageItem.attendees}}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <div class="">
                No data found.
              </div>
            </tr>
          </template>
        </tbody>
      </b-table-simple>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <span class="p-1">
            <b-button
              
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="cancelAttendees()"
            >
              Cancel
            </b-button>
          </span>
          <span class="p-1">
            <b-button
              variant="outline-primary"
              size="sm"
              class="float-right"
              @click="saveAttendees()"
            >
              Save
            </b-button>
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
/*eslint-disable*/
import { baseApiUrl, appBaseUrl } from "../../config.js"
import axios from "axios"
import Paginate from 'vuejs-paginate'
export default {
name: 'PreliminaryAssessment',
components: {
  Paginate
},
data() {
  return {
    openAttendeesModalFlag: false,
    textAttendees: "",
    disciplineFilterSet: ['Cost Estimation and Quantities','Environmental','Road Design','Civil','Steering Committee','Health and Safety','Utilities','Flooding','Structures','Geotechnical','Management','Independent Review','Traffic','Drainage','Pavements','GIS','Commercial','Drafting','Lighting'],
    statusFilterSet: ['Approved','Pending'],
    workPackageFilterSet: ['LH2RH','FB2L','RH2FB','CRR'],
    typeFilterSet: ['Constructability','Health & Safety','3D Model Discussion','Coordination','Design Discussion'],
    priorityFilterSet: ['Critical', 'Major', 'Minor'],
    riskRatingFilterSet: ['A - Very High','B - High','C - Medium','D - Low','-'],
    selectedConsequenceCategory: "All",
    selectedPrimaryAssetType: "All",
    selectedAssetSubType: "All",
    selectedRiskType: "All",
    selectedClimateHazard: "All",
    selectedPhase:"All",
    selectedRiskOwner: "All",
    selectedMateriality: "All",
    riskDescriptionString: "",
    designControlMeasureString: "",
    ownerString: "",
    stakeholdersString: "",
    selectedNFRiskScore: "All",
    selectedMFRiskScore: "All",
    selectedFFRiskScore: "All",
    selectedNFLikelihood: "All",
    selectedMFLikelihood: "All",
    selectedFFLikelihood: "All",
    selectedNFConsequence: "All",
    selectedMFConsequence: "All",
    selectedFFConsequence: "All",
    appBaseUrl: appBaseUrl,
    searchCreatedBy: "",
    searchAddressedTo: "",
    attachmentNameLengthLimit: 15,
    // numberOfPages: 0,
    perPageCount: 30,
    selectedPageNumber: 1,
    isCRAEditEnabled: false,
    preAssessmentItems: [],
    currentCRAEntry: [],
    currentStage2Status:'',
    stageAttendeesList: []
  }
},
computed: {
  riskCounterCRA: {
    get() {
      var counter = {
        countLow: 0,
        countMedium: 0,
        countHigh: 0,
        countVeryHigh: 0
      }
      this.$store.state.mapModule.currentProjectCRAEntryList.forEach(item=>{
          if(item.riskRatingNFCode && item.riskRatingNFCode == "low"){
            counter.countLow += 1
          }
          else if(item.riskRatingNFCode && item.riskRatingNFCode == "medium"){
            counter.countMedium += 1
          }
          else if(item.riskRatingNFCode && item.riskRatingNFCode == "high"){
            counter.countHigh += 1
          }
          else if(item.riskRatingNFCode && item.riskRatingNFCode == "very_high"){
            counter.countVeryHigh += 1
          }
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode && item.riskRatingMFCode == "low"){
            counter.countLow += 1
          }
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode && item.riskRatingMFCode == "medium"){
            counter.countMedium += 1
          } 
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode && item.riskRatingMFCode == "high"){
            counter.countHigh += 1
          } 
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode && item.riskRatingMFCode == "very_high"){
            counter.countVeryHigh += 1
          }
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode === "" && item.riskRatingFFCode && item.riskRatingFFCode == "low"){
            counter.countLow += 1
          }
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode === "" && item.riskRatingFFCode && item.riskRatingFFCode == "medium"){
            counter.countMedium += 1
          } 
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode === "" && item.riskRatingFFCode && item.riskRatingFFCode == "high"){
            counter.countHigh += 1
          } 
          else if(item.riskRatingNFCode === "" && item.riskRatingMFCode === "" && item.riskRatingFFCode && item.riskRatingFFCode == "very_high"){
            counter.countVeryHigh += 1
          }  
      })
      return counter
    }
  },
  primaryAssetTypeOptions: {
      get() {
          return this.$store.state.mapModule.primaryAssetTypeList.filter(obj=>obj.isActive == true)
      }
  },
  climateHazardOptions: {
      get() {
          return this.$store.state.mapModule.climateHazardList
      }
  },
  phaseOptions: {
      get() {
          return this.$store.state.mapModule.riskOwnersList
      }
  },
  assessmentLikelihoodOptions: {
      get() {
          return this.$store.state.mapModule.assessmentLikelihoodList
      }
  },
  assessmentConsequenceOptions: {
      get() {
          return this.$store.state.mapModule.assessmentConsequencesList
      }
  },
  assessmentRatingOptions: {
      get() {
          return this.$store.state.mapModule.assessmentRatingList
      }
  },
  riskOwnerOptions: {
      get() {
          return this.$store.state.mapModule.riskOwnersList
      }
  },
  discussionList: {
    get() {
      return this.$store.state.mapModule.initialDiscussions
    }
  },
  projectId: {
      get(){
          return this.$store.state.mainModule.currentProjectId
      }
  },
  projectName: {
      get(){
          this.currentStage2Status = JSON.parse(JSON.stringify(this.$store.state.mainModule.currentProject.stage_2_status));
          return this.$store.state.mainModule.currentProject.name
      }
  },
  projectCode: {
      get(){
          return this.$store.state.mainModule.currentProject.code
      }
  },
  craEntryList: {
      get() {
        // return this.$store.state.mapModule.craEntryList.filter(obj=>obj.project.id == this.$store.state.mainModule.currentProjectId)[0].entries
        // this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}  
        return this.$store.state.mapModule.currentProjectCRAEntryList
          
      },
      set(value){
        this.preAssessmentItems = value
      }
  },
  primaryAssetTypeFilterSet: {
      get() {
          return this.$store.state.mapModule.primaryAssetTypeList.map(a => a.label);
      }
  },
  assetSubTypeFilterSet: {
      get() {
          return this.$store.state.mapModule.assetSubTypeList.map(a => a.label);
      }
  },
  climateHazardFilterSet: {
      get() {
          return this.$store.state.mapModule.climateHazardList.map(a => a.label);
      }
  },
  phaseFilterSet: {
      get() {
          return this.$store.state.mapModule.riskOwnersList.map(a => a.label);
      }
  },
  riskTypeFilterSet: {
      get() {
          return this.$store.state.mapModule.riskTypesList.map(a => a.label);
      }
  },
  materialityFilterSet: {
      get() {
          return this.$store.state.mapModule.materialityList.map(a => a.label);
      }
  },
  consequenceCategoryFilterSet: {
      get() {
          return this.$store.state.mapModule.consequenceCategoriesList.map(a => a.label);
      }
  },
  riskOwnerFilterSet: {
      get() {
          return this.$store.state.mapModule.riskOwnersList.map(a => a.label);
      }
  },
  assessmentLikelihoodFilterSet: {
      get() {
          return this.$store.state.mapModule.assessmentLikelihoodList.map(a => a.label);
      }
  },
  assessmentConsequenceFilterSet: {
      get() {
          return this.$store.state.mapModule.assessmentConsequencesList.map(a => a.label);
      }
  },
  assessmentRatingFilterSet: {
      get() {
          return this.$store.state.mapModule.assessmentRatingList.map(a => a.label);
      }
  },
  projectAssessmentStakeholdersList: {
      get() {
        console.log("watch triggered")
        this.stageAttendeesList = this.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_2");
        return this.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_2");
      }
  },
  stageName: {
      get() {
        return this.$store.state.mainModule.assessmentStagesList.filter(obj => obj.code == "stage_2")[0].name;
      }
  },
  filteredList: {
    get() {

      var finalList = []
      // this.currentCRAEntry.forEach(item=>{
      //     // finalList.push(item.entries)
      //     item.entries.forEach(entry=>{
      //         entry.projectName = item.project.name
      //         entry.isEditable = false
      //         finalList.push(entry)
      //     })
      // })
      // this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}  
      
      this.craEntryList.forEach(item=>{
          finalList.push(item)
      })
      if (this.selectedPrimaryAssetType != "All") {
        finalList = finalList.filter(obj=>obj.primaryAssetTypeLabel.includes(this.selectedPrimaryAssetType))
      }
      if (this.selectedAssetSubType != "All") {
        finalList = finalList.filter(obj=>obj.assetSubTypeLabel == this.selectedAssetSubType)
      }
      if (this.selectedClimateHazard != "All") {
        finalList = finalList.filter(obj=>obj.climateHazardLabel == this.selectedClimateHazard)
      }
      if (this.selectedPhase != "All") {
        finalList = finalList.filter(obj=>obj.riskOwnerLabel == this.selectedPhase)
      }
      if (this.selectedRiskType != "All") {
        finalList = finalList.filter(obj=>obj.riskTypeLabel == this.selectedRiskType)
      }
      if (this.selectedConsequenceCategory != "All") {
        finalList = finalList.filter(obj=>obj.consequenceCategoryLabel == this.selectedConsequenceCategory)
      }
      if (this.selectedRiskOwner != "All") {
        finalList = finalList.filter(obj=>obj.riskOwnerLabel == this.selectedRiskOwner)
      }
      if (this.selectedMateriality != "All") {
        finalList = finalList.filter(obj=>obj.materialityLabel == this.selectedMateriality)
      }
      if (this.riskDescriptionString != "") {
        finalList = finalList.filter(obj=>obj.risk_description && obj.risk_description.toLowerCase().search(this.riskDescriptionString.toLowerCase())!=-1)
      }
      if (this.designControlMeasureString != "") {
        finalList = finalList.filter(obj=>obj.current_design_control_measure && obj.current_design_control_measure.toLowerCase().search(this.designControlMeasureString.toLowerCase())!=-1)
      }
      if (this.ownerString != "") {
        finalList = finalList.filter(obj=>obj.assigned_owner && obj.assigned_owner.toLowerCase().search(this.ownerString.toLowerCase())!=-1)
      }
      if (this.stakeholdersString != "") {
        finalList = finalList.filter(obj=>obj.stakeholders && obj.stakeholders.toLowerCase().search(this.stakeholdersString.toLowerCase())!=-1)
      }
      if (this.selectedNFRiskScore != "All") {
          if (this.selectedNFRiskScore =='-') {
              finalList = finalList.filter(obj=>obj.riskRatingNFLabel == "")
          }
          else {
              finalList = finalList.filter(obj=>obj.riskRatingNFLabel == this.selectedNFRiskScore)
          }
      }
      if (this.selectedMFRiskScore != "All") {
          if (this.selectedMFRiskScore =='-') {
              finalList = finalList.filter(obj=>obj.riskRatingMFLabel == "")
          }
          else {
              finalList = finalList.filter(obj=>obj.riskRatingMFLabel == this.selectedMFRiskScore)
          }
      }
      if (this.selectedFFRiskScore != "All") {
          if (this.selectedFFRiskScore =='-') {
              finalList = finalList.filter(obj=>obj.riskRatingFFLabel == "")
          }
          else {
              finalList = finalList.filter(obj=>obj.riskRatingFFLabel == this.selectedFFRiskScore)
          }
      }
      if (this.selectedNFLikelihood != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentLikelihoodNFLabel == this.selectedNFLikelihood)
      }
      if (this.selectedMFLikelihood != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentLikelihoodMFLabel == this.selectedMFLikelihood)
      }
      if (this.selectedFFLikelihood != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentLikelihoodFFLabel == this.selectedFFLikelihood)
      }
      if (this.selectedNFConsequence != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentConsequenceNFLabel == this.selectedNFConsequence)
      }
      if (this.selectedMFConsequence != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentConsequenceMFLabel == this.selectedMFConsequence)
      }
      if (this.selectedFFConsequence != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentConsequenceFFLabel == this.selectedFFConsequence)
      }
      // if (['climateAssetContext','preScreening'].includes(this.currentCRAStageCode)) {
      //     finalList.sort((a, b) => b.materiality_id - a.materiality_id);
      // } else if (this.currentCRAStageCode == 'detailedRiskAssessment') {
      //     finalList.sort((a, b) => b.risk_rating_nf_id - a.risk_rating_nf_id||b.risk_rating_mf_id - a.risk_rating_mf_id||b.risk_rating_ff_id - a.risk_rating_ff_id);
      // }
      // return finalList
      return finalList.map(item => ({...item, isEdit: false}))
    }
  },
  numberOfPages: {
    get() {
      return Math.ceil(this.filteredList.length / this.perPageCount)
    }
  },
  craEntryListFinal: {
    get() {
      const current = this
      current.preAssessmentItems = current.filteredList.map(item => ({...item, isEdit: false}))
      if(current.numberOfPages >= current.selectedPageNumber){
        return current.preAssessmentItems.slice((current.selectedPageNumber - 1) * current.perPageCount, current.selectedPageNumber * current.perPageCount)
      }
      else{
        current.selectedPageNumber = 1
        return current.preAssessmentItems.slice((current.selectedPageNumber - 1) * current.perPageCount, current.selectedPageNumber * current.perPageCount)
      }

    }
  },
  currentCRAStageCode: {
      get() {
          return this.$store.state.mapModule.selectedCRAStage.code
      }
  }
},
watch: {
  currentStage2Status: function(newVal, oldVal) {
            if (newVal != this.$store.state.mainModule.currentProject.stage_2_status) {
                let data = {
                    projectId: this.$store.state.mainModule.currentProjectId,
                    stage: 'stageTwo',
                    stageStatus: newVal
                }
                let url = baseApiUrl + 'projectStageStatus'
                let config  = {
                    responseType: 'json'
                }
                axios.put(url,data,config).then(()=>{
                    this.$notify({
                        group: 'successNotifications',
                        title: "Project Stage Two Status Update",
                        type: 'success',
                        text: "Update to the project stage two status completed.",
                        width: 500,
                        duration: 1500
                    })
                    // update project list and project details
                    this.$store.dispatch('mainModule/fetchProjects').then(()=>{
                        this.$store.commit('mainModule/setCurrentProject',this.$store.state.mainModule.currentProjectId)
                    })
                })
                .catch((err)=>{
                    this.$notify({
                        group: 'errors',
                        title: "No Update done",
                        type: 'error',
                        text: "No Update to the project stage two status completed",
                        width: 500,
                        duration: 1500
                    })
                })
            }
        },
  'craEntryListFinal': {
    handler (newValue, oldValue) {
      newValue.forEach((item) => {
        if (item.riskAssessmentLikelihoodNFCode && item.riskAssessmentConsequenceNFCode) {
                if (item.riskAssessmentLikelihoodNFCode == 'almost_unprecedented') {
                    if (['severe','catastrophic'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'medium') {
                            item.riskRatingNFCode = 'medium'
                            item.riskRatingNFLabel = 'C - Medium'
                        }
                    } 
                    else {
                        if (item.riskRatingNFCode != 'low') {
                            item.riskRatingNFCode = 'low'
                            item.riskRatingNFLabel = "D - Low"
                        }
                    }
                } 
                else if (item.riskAssessmentLikelihoodNFCode == 'very_unlikely') {
                    if (['catastrophic'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'high') {
                            item.riskRatingNFCode = 'high'
                            item.riskRatingNFLabel = "B - High"

                        }
                    } 
                    else if (['severe','major'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'medium') {
                            item.riskRatingNFCode = 'medium'
                            item.riskRatingNFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingNFCode != 'low') {
                            item.riskRatingNFCode = 'low'
                            item.riskRatingNFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodNFCode == 'unlikely') {
                    if (['catastrophic', 'severe'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'high') {
                            item.riskRatingNFCode = 'high'
                            item.riskRatingNFLabel = "B - High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'medium') {
                            item.riskRatingNFCode = 'medium'
                            item.riskRatingNFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingNFCode != 'low') {
                            item.riskRatingNFCode = 'low'
                            item.riskRatingNFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodNFCode == 'likely') {
                    if (['catastrophic'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'very_high') {
                            item.riskRatingNFCode = 'very_high'
                            item.riskRatingNFLabel = "A - Very High"
                        }
                    } 
                    else if (['severe','major'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'high') {
                            item.riskRatingNFCode = 'high'
                            item.riskRatingNFLabel = "B - High"
                        }
                    }
                    else if (['moderate','minor'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'medium') {
                            item.riskRatingNFCode = 'medium'
                            item.riskRatingNFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingNFCode != 'low') {
                            item.riskRatingNFCode = 'low'
                            item.riskRatingNFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodNFCode == 'very_likely') {
                    if (['catastrophic', 'severe'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'very_high') {
                            item.riskRatingNFCode = 'very_high'
                            item.riskRatingNFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'high') {
                            item.riskRatingNFCode = 'high'
                            item.riskRatingNFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.riskRatingNFCode != 'medium') {
                            item.riskRatingNFCode = 'medium'
                            item.riskRatingNFLabel = 'C - Medium'
                        }
                    }
                }
                else {
                    if (['catastrophic', 'severe', 'major'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'very_high') {
                            item.riskRatingNFCode = 'very_high'
                            item.riskRatingNFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','minor'].includes(item.riskAssessmentConsequenceNFCode)) {
                        if (item.riskRatingNFCode != 'high') {
                            item.riskRatingNFCode = 'high'
                            item.riskRatingNFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.riskRatingNFCode != 'medium') {
                            item.riskRatingNFCode = 'medium'
                            item.riskRatingNFLabel = 'C - Medium'
                        }
                    }
                }

            }
            else {
                if (item.riskRatingNFCode) {
                    item.riskRatingNFCode = ''
                    item.riskRatingNFLabel = ''
                }
            }
            // MF autocalc section
            if (item.riskAssessmentLikelihoodMFCode && item.riskAssessmentConsequenceMFCode) {
                if (item.riskAssessmentLikelihoodMFCode == 'almost_unprecedented') {
                    if (['severe','catastrophic'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'medium') {
                            item.riskRatingMFCode = 'medium'
                            item.riskRatingMFLabel = 'C - Medium'
                        }
                    } 
                    else {
                        if (item.riskRatingMFCode != 'low') {
                            item.riskRatingMFCode = 'low'
                            item.riskRatingMFLabel = "D - Low"
                        }
                    }
                } 
                else if (item.riskAssessmentLikelihoodMFCode == 'very_unlikely') {
                    if (['catastrophic'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'high') {
                            item.riskRatingMFCode = 'high'
                            item.riskRatingMFLabel = "B - High"

                        }
                    } 
                    else if (['severe','major'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'medium') {
                            item.riskRatingMFCode = 'medium'
                            item.riskRatingMFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingMFCode != 'low') {
                            item.riskRatingMFCode = 'low'
                            item.riskRatingMFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodMFCode == 'unlikely') {
                    if (['catastrophic', 'severe'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'high') {
                            item.riskRatingMFCode = 'high'
                            item.riskRatingMFLabel = "B - High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'medium') {
                            item.riskRatingMFCode = 'medium'
                            item.riskRatingMFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingMFCode != 'low') {
                            item.riskRatingMFCode = 'low'
                            item.riskRatingMFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodMFCode == 'likely') {
                    if (['catastrophic'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'very_high') {
                            item.riskRatingMFCode = 'very_high'
                            item.riskRatingMFLabel = "A - Very High"
                        }
                    } 
                    else if (['severe','major'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'high') {
                            item.riskRatingMFCode = 'high'
                            item.riskRatingMFLabel = "B - High"
                        }
                    }
                    else if (['moderate','minor'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'medium') {
                            item.riskRatingMFCode = 'medium'
                            item.riskRatingMFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingMFCode != 'low') {
                            item.riskRatingMFCode = 'low'
                            item.riskRatingMFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodMFCode == 'very_likely') {
                    if (['catastrophic', 'severe'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'very_high') {
                            item.riskRatingMFCode = 'very_high'
                            item.riskRatingMFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'high') {
                            item.riskRatingMFCode = 'high'
                            item.riskRatingMFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.riskRatingMFCode != 'medium') {
                            item.riskRatingMFCode = 'medium'
                            item.riskRatingMFLabel = 'C - Medium'
                        }
                    }
                }
                else {
                    if (['catastrophic', 'severe', 'major'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'very_high') {
                            item.riskRatingMFCode = 'very_high'
                            item.riskRatingMFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','minor'].includes(item.riskAssessmentConsequenceMFCode)) {
                        if (item.riskRatingMFCode != 'high') {
                            item.riskRatingMFCode = 'high'
                            item.riskRatingMFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.riskRatingMFCode != 'medium') {
                            item.riskRatingMFCode = 'medium'
                            item.riskRatingMFLabel = 'C - Medium'
                        }
                    }
                }

            }
            else {
                if (item.riskRatingMFCode) {
                    item.riskRatingMFCode = ''
                    item.riskRatingMFLabel = ''
                }
            }
            // FF  autocalc section
            if (item.riskAssessmentLikelihoodFFCode && item.riskAssessmentConsequenceFFCode) {
                if (item.riskAssessmentLikelihoodFFCode == 'almost_unprecedented') {
                    if (['severe','catastrophic'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'medium') {
                            item.riskRatingFFCode = 'medium'
                            item.riskRatingFFLabel = 'C - Medium'
                        }
                    } 
                    else {
                        if (item.riskRatingFFCode != 'low') {
                            item.riskRatingFFCode = 'low'
                            item.riskRatingFFLabel = "D - Low"
                        }
                    }
                } 
                else if (item.riskAssessmentLikelihoodFFCode == 'very_unlikely') {
                    if (['catastrophic'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'high') {
                            item.riskRatingFFCode = 'high'
                            item.riskRatingFFLabel = "B - High"

                        }
                    } 
                    else if (['severe','major'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'medium') {
                            item.riskRatingFFCode = 'medium'
                            item.riskRatingFFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingFFCode != 'low') {
                            item.riskRatingFFCode = 'low'
                            item.riskRatingFFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodFFCode == 'unlikely') {
                    if (['catastrophic', 'severe'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'high') {
                            item.riskRatingFFCode = 'high'
                            item.riskRatingFFLabel = "B - High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'medium') {
                            item.riskRatingFFCode = 'medium'
                            item.riskRatingFFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingFFCode != 'low') {
                            item.riskRatingFFCode = 'low'
                            item.riskRatingFFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodFFCode == 'likely') {
                    if (['catastrophic'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'very_high') {
                            item.riskRatingFFCode = 'very_high'
                            item.riskRatingFFLabel = "A - Very High"
                        }
                    } 
                    else if (['severe','major'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'high') {
                            item.riskRatingFFCode = 'high'
                            item.riskRatingFFLabel = "B - High"
                        }
                    }
                    else if (['moderate','minor'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'medium') {
                            item.riskRatingFFCode = 'medium'
                            item.riskRatingFFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.riskRatingFFCode != 'low') {
                            item.riskRatingFFCode = 'low'
                            item.riskRatingFFLabel = "D - Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodFFCode == 'very_likely') {
                    if (['catastrophic', 'severe'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'very_high') {
                            item.riskRatingFFCode = 'very_high'
                            item.riskRatingFFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'high') {
                            item.riskRatingFFCode = 'high'
                            item.riskRatingFFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.riskRatingFFCode != 'medium') {
                            item.riskRatingFFCode = 'medium'
                            item.riskRatingFFLabel = 'C - Medium'
                        }
                    }
                }
                else {
                    if (['catastrophic', 'severe', 'major'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'very_high') {
                            item.riskRatingFFCode = 'very_high'
                            item.riskRatingFFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','minor'].includes(item.riskAssessmentConsequenceFFCode)) {
                        if (item.riskRatingFFCode != 'high') {
                            item.riskRatingFFCode = 'high'
                            item.riskRatingFFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.riskRatingFFCode != 'medium') {
                            item.riskRatingFFCode = 'medium'
                            item.riskRatingFFLabel = 'C - Medium'
                        }
                    }
                }

            }
            else {
                if (item.riskRatingFFCode) {
                    item.riskRatingFFCode = ''
                    item.riskRatingFFLabel = ''
                }
            }
      })
    },
    deep: true
  },
},
methods: {
  goToObject: function(id) {
    sessionStorage.origURL = appBaseUrl+id
  },
  downloadFile: function(fileId, type) {
    let formData = new FormData();
    let url = baseApiUrl + "downloadAttachment";
    let config = {
      headers: {
        'content-type': 'application/octet-stream'
      },
      responseType: 'blob'
    }
    formData.append("fileId", fileId);
    formData.append("type", type);
    axios.post(url, formData, config).then((result) => {
      var disposition = result.headers['content-disposition'];
      // fix this matches
      var matches = /=(.*)/.exec(disposition);
      var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
      // filename = filename.replace(/"*/g,"");
      var type = result.headers['content-type'];
      var link = document.createElement('a');

      document.body.appendChild(link)
      link.style = "display: none"
      var blob = new Blob(
        [result.data],
        {type: result.headers['content-type']}
      );
      var url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
      // link.remove();
    });
  },
  selectNewPage: function(pageNumber) {
    const current = this
    current.selectedPageNumber = pageNumber
  },
  exportReport: function() {
    const current = this
    let formData = new FormData();
    formData.append('projectCode',current.projectCode)
    formData.append('reportType','preliminary_assessment')
    let url = baseApiUrl + "exportCRAReport";
    let config = {
      headers: {
        'content-type': 'application/octet-stream'
      },
      responseType: 'blob'
    }
    axios.post(url, formData, config).then((result) => {
      var disposition = result.headers['content-disposition'];
      // fix this matches
      var matches = /=(.*)/.exec(disposition);
      var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
      // filename = filename.replace(/"*/g,"");
      var type = result.headers['content-type'];
      var link = document.createElement('a');

      document.body.appendChild(link)
      link.style = "display: none"
      var blob = new Blob(
        [result.data],
        {type: result.headers['content-type']}
      );
      var url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    });
  },
  clearAllFilters: function() {
      this.selectedConsequenceCategory =  "All"
      this.selectedPrimaryAssetType =  "All"
      this.selectedAssetSubType =  "All"
      this.selectedRiskType =  "All"
      this.selectedClimateHazard =  "All"
      this.selectedRiskOwner =  "All"
      this.selectedMateriality =  "All"
      this.riskDescriptionString =  ""
  },
  selectCRAEntryFromList: function(craEntryId) {
      this.$store.dispatch('mapModule/setCurrentCRAEntryProcess', craEntryId).then(()=>{
          this.switchToFormMode()
      })
  },
  switchToFormMode: function() {
      // turn on
      this.$store.commit('mapModule/toggleIsViewDiscussions')
      var flexContainer = document.getElementById("flexContainer")
      var sidebarList = document.getElementById("craListSidebar")
      flexContainer.classList.remove("displayNone")
      sidebarList.classList.add("displayNone")
  },
  editRowHandler: function(data) {
    const current = this
    current.isCRAEditEnabled = !current.isCRAEditEnabled
    current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit = !current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit
    current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit = !current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit
    
    if(!current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit){
      current.$store.dispatch("mapModule/fetchCRAEntries").then(()=>{
        this.$store.commit('mapModule/setCurrentProjectCRAEntries', current.projectId)
        this.$notify({
              group: 'successNotifications',
              title: "Cancelled",
              type: 'warning',
              text: "Edit cancelled",
              width: 500,
              duration: 3000
          })
      })

    }
  },
  saveRowHandler(data) {
    const current = this
    current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit = !current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit
    var currentPreAssessItem = current.preAssessmentItems.filter(obj=>obj.id == data.id)[0]
    current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit = !current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit
    currentPreAssessItem.riskAssessmentLikelihoodNFCode = data.riskAssessmentLikelihoodNFCode
    // current.currentCRAEntry.filter(obj=>obj.id == data.id)[0].isEdit = !current.currentCRAEntry.filter(obj=>obj.id == data.id)[0].isEdit
    this.$store.dispatch('mapModule/updatePreScreenCRAEntry', data).then(()=>{
      
        this.$store.dispatch('mapModule/updateRiskAssessmentCRAEntry', data).then(()=>{
          current.$store.dispatch("mapModule/fetchCRAEntries").then(()=>{
            this.$store.commit('mapModule/setCurrentProjectCRAEntries', current.projectId)
          })
          this.$notify({
              group: 'successNotifications',
              title: "Success",
              type: 'success',
              text: "Successfully saved ",
              width: 500,
              duration: 3000
          })
          // this.$bvModal.hide("filterCRAModal")

        })
    })
  },
  deleteRowHandler(data) {
    const current = this
    this.$store.dispatch('mapModule/deleteCRAEntry', data).then(()=>{
        current.$store.dispatch("mapModule/fetchCRAEntries").then(()=>{
            this.$notify({
                group: 'successNotifications',
                title: "Success",
                type: 'success',
                text: "Successfully deleted ",
                width: 500,
                duration: 3000
            })
        })
        
    })
  },
  addAttendee(){
    const current = this
    if(current.textAttendees){
      current.stageAttendeesList.push({
        id: null,
        projectId: current.projectId,
        code: "stage_2",
        attendees: current.textAttendees,
        date: new Date().toISOString().substring(0,10)
      })

    }
    current.textAttendees = ""
  },
  saveAttendees(){
    console.log("Save Attendees Triggered")
    const current = this
    var data = current.stageAttendeesList.filter(obj=>obj.id == null)
    if(data.length > 0){
      // save
      this.$store.dispatch('mainModule/updateProjectAssessmentStakeholders', data).then(()=>{
        current.$store.dispatch("mainModule/fetchProjectAssessmentStakeholders", current.projectId).then(()=>{
            this.$notify({
                group: 'successNotifications',
                title: "Success",
                type: 'success',
                text: "Successfully added attendees",
                width: 500,
                duration: 3000
            })
            
        })
      })
    }
    current.openAttendeesModalFlag = false
  },
  cancelAttendees(){
    console.log("Cancel Attendees Triggered")
    const current = this
    setTimeout(() => {
        current.stageAttendeesList = []
    }, 100);
    setTimeout(() => {
        current.stageAttendeesList = current.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_2");
    }, 100);
    // current.stageAttendeesList = current.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_2");
    current.textAttendees = ""
    current.openAttendeesModalFlag = false
  }
},
mounted() {
  const current = this
  current.$store.dispatch('mapModule/fetchCRAEntries')
  // this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}
},
beforeCreate(){
  this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}
  
  this.preAssessmentItems = this.filteredList
}
}
</script>

<style scoped>
.table-responsive {
    max-height:70vh;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.bottomright {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.btn-orange {
  background-color: #E66200 !important;
  border-color: #E66200 !important;
}
.btn-orange:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #E66200 !important;
  border-color: #E66200 !important;
}
.page-link {
  color: #E66200 !important;
}
.page-link:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
}
.text-orange {
    color: rgb(255, 136, 0);
}
.materiality-high {
    color: #fff;
    background-color: orangered !important;
}

.materiality-medium {
    color: #fff;
    background-color: orange !important;
}

.materiality-low {
    color: #fff;
    background-color: lightsalmon !important;
}
.table-th-border-custom {
  border: 1px solid #dee2e6;
}
.text-orange {
  color: rgb(255, 136, 0);
}

.badge-orange {
  background-color: rgb(255, 136, 0);
}
.card-body {
    padding: 0 !important;
}
.hideCustom {
  display: none;
}
.testSticky {
    position: sticky;
}

.prelimAssessmentPage {
    overflow:hidden;
}
</style>