<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
          <div class="col text-right">
            <b-button 
              variant="outline-secondary"
              size="sm"
              v-b-popover.hover="'Summary of risk ratings associated with each key climate change hazard.'" >
              <i class="fas fa-question-circle"></i>
            </b-button>
          </div>
      </div>
      <PieChart
        v-if="dataLoaded"
        :key="inputProjectCode"
        :chartData="baseChartData"
        :options="options"
        :height="200"
        :flag="flag"></PieChart>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import PieChart from "./PieChart.vue"
import Chart from 'chart.js'
export default {
  name: "ClimateHazardsMediumToExtremeChart",
  components: {
    PieChart
  },
  props: {
    projCRADetails: Array,
    inputProjectCode: String,
    title: String
  },
  data() {
    return {
      flag: 0,
      defaultBaseChartData: {
        datasets:[
          {
            fill: true,
            label: "",
            backgroundColor: ["#ffc107","#FF9900","#dd4b39"],
            data: [0,0,0]
          }
        ],
        labels: [
          "medium",
          "high",
          "very_high"
        ],
      },
      baseChartData: {
        labels: [
        'Heatwaves',
        'Drought periods',
        'Maximum temperatures',
        'Compounded extreme events',
        'Flooding',
        'Bushfire weather',
        'Extreme wind',
        'Rainfall intensity',
        'Sea level rise and storm surge'
        ],
        datasets:[
          {
            
            backgroundColor: [
            "#f66d9b",
            "#9561e2",
            "#6574cd",
            "#e3342f",
            "#f6993f",
            "#ffed4a",
            "#38c172",
            "#4dc0b5",
            "#3490dc"
            ],
            data: [21, 79, 14, 21, 79, 14, 21, 79, 14],
            label: 2090
          },
          {
            label: 2050,
            backgroundColor: [
            "#f66d9b",
            "#9561e2",
            "#6574cd",
            "#e3342f",
            "#f6993f",
            "#ffed4a",
            "#38c172",
            "#4dc0b5",
            "#3490dc"
            ],
            data: [33, 67, 45, 33, 67, 45,33, 67, 45]
          },
          {
            label: 2030,
            backgroundColor: [
            "#f66d9b",
            "#9561e2",
            "#6574cd",
            "#e3342f",
            "#f6993f",
            "#ffed4a",
            "#38c172",
            "#4dc0b5",
            "#3490dc"
            ],
            data: [20, 80, 46, 20, 80, 46, 20, 80, 46]
          }
        ],
      },
      options: {
        title: {
          text: "Sources (climate hazards) of medium high and very high risks",
          display: true,
          fontFamily: "Segoe UI",
          fontSize: 20
        },
        legend: {
          position: "left",
          labels: {
            fontStyle: 'bold',
            fontFamily: 'Segoe UI',
            // generateLabels: function(chart) {
            //   console.log("generateLabels")
            //   console.log(chart)
            //   console.log(Chart.overrides)
            // }
          },
          
        },
        // legend: {
        //   labels: {
        //     generateLabels: function(chart) {
        //       // Get the default label list
        //       const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
        //       const labelsOriginal = original.call(this, chart);

        //       // Build an array of colors used in the datasets of the chart
        //       let datasetColors = chart.data.datasets.map(function(e) {
        //         return e.backgroundColor;
        //       });
        //       datasetColors = datasetColors.flat();

        //       // Modify the color and hide state of each label
        //       labelsOriginal.forEach(label => {
        //         // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
        //         label.datasetIndex = (label.index - label.index % 2) / 2;

        //         // The hidden state must match the dataset's hidden state
        //         label.hidden = !chart.isDatasetVisible(label.datasetIndex);

        //         // Change the color to match the dataset
        //         label.fillStyle = datasetColors[label.index];
        //       });

        //       return labelsOriginal;
        //     }
        //   },
        //   onClick: function(mouseEvent, legendItem, legend) {
        //     // toggle the visibility of the dataset from what it currently is
        //     legend.chart.getDatasetMeta(
        //       legendItem.datasetIndex
        //     ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
        //     legend.chart.update();
        //   }
        // },
        tooltips: {
          enabled: true,
          callbacks: {
              title: function(tooltipItem, data) {
                return data.datasets[tooltipItem[0]['datasetIndex']].label;
              }
            }
        }
      },
      yearCategories: [
        {
          code: "riskRatingFFCode",
          label: "2090"
        },
        {
          code: "riskRatingMFCode",
          label: "2050"
        },
        {
          code: "riskRatingNFCode",
          label: "2030"
        }
        
      ]
    }
  },
  computed: {
    // bschart: {
    //   get(){
    //     let bschart = this.defaultBaseChartData
    //     console.log("here")
    //     console.log("medium",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length)
    //     console.log("high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length)
    //     console.log("very_high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length)
    //     var medium = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length;
    //     var high = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length;
    //     var veryHigh = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length;
    //     bschart.datasets[0].data = [medium,high,veryHigh]
    //     // this.options.title.text = ""
    //     bschart.labels.forEach((item,index)=>{
    //       bschart.labels[index] = item + ": " + (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == item)).length
    //     })
    //     return bschart
    //   }
    // }
  },
  watch: {
    projectCRAEntryList(newValue) {
        // console.log("watch",newValue)
        this.projectCRAEntryList = newValue
        // const current = this;
        // console.log("here")
        // console.log("medium",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length)
        // console.log("high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length)
        // console.log("very_high",(this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length)
        // var medium = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "medium")).length;
        // var high = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "high")).length;
        // var veryHigh = (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == "very_high")).length;
        // this.baseChartData.datasets[0].data = [medium,high,veryHigh]
        // this.options.title.text = ""
        // this.baseChartData.labels.forEach((item,index)=>{
        //   this.baseChartData.labels[index] = item + ": " + (this.projectCRAEntryList.filter(obj => obj.riskRatingNFCode == item)).length
        // })
    },
  },
  // computed: {
  //   currentProjectCRAEntryList: {
  //       get() {
  //         return this.$store.state.mapModule.currentProjectCRAEntryList
            
  //       }
  //   },
  // },
  methods: {
    setupData: function() {
      this.dataLoaded = false;

    this.yearCategories.forEach((item,index)=>{
      var heatwaves = (this.projCRADetails.filter(obj => obj.climateHazardCode == "heatwaves" &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var drought = (this.projCRADetails.filter(obj => obj.climateHazardCode == "drought"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var maximum_temperatures = (this.projCRADetails.filter(obj => obj.climateHazardCode == "maximum_temperatures"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var extreme_events = (this.projCRADetails.filter(obj => obj.climateHazardCode == "extreme_events"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var flooding = (this.projCRADetails.filter(obj => obj.climateHazardCode == "flooding"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var bushfire = (this.projCRADetails.filter(obj => obj.climateHazardCode == "bushfire"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var wind = (this.projCRADetails.filter(obj => obj.climateHazardCode == "wind"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var rainfall = (this.projCRADetails.filter(obj => obj.climateHazardCode == "rainfall"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;
      var sea_level_storm_surge = (this.projCRADetails.filter(obj => obj.climateHazardCode == "sea_level_storm_surge"  &&
                      (obj[item.code] == "medium" || 
                      obj[item.code] == "high" ||
                      obj[item.code] == "very_high"
                      )
                       )).length;

      this.baseChartData.datasets[index].data = [
        heatwaves,
        drought,
        maximum_temperatures,
        extreme_events,
        flooding,
        bushfire,
        wind,
        rainfall,
        sea_level_storm_surge
      ]
      // console.log(this.baseChartData.datasets[index].data)
    })

      
      this.dataLoaded = true;
    }
  },
  beforeMount() {
    this.setupData()
    // this.seletedYearCategory = riskYearCode
    // this.yearDropdownText = riskYear
    

  }
}
</script>

<style lang="css" scoped>
</style>
